import { useAuth, AuthActionTypes, getUser } from '@devd-client/devd/auth';
import styled from 'styled-components';
import IntegrationOptions from '../components/IntegrationOptions';
import {
  DateFilter,
  MetaTags,
  TeamMenu,
  durationData,
  removeEmptyQueryParams,
  useAiAssistantStore,
  useQueryState,
  useToastHook,
} from '@devd-client/devd/components';
import { Box, Button, Flex, Image, Spinner, Text } from '@chakra-ui/react';
import Insights from '../components/Insights';
import Summary from '../components/Summary';
import InsightCards from '../components/InsightCards';
import { getGreetingByTime } from '../helpers/home.utils';
import { useEffect, useState } from 'react';
import { Chatbox } from '../components/Chatbox';
import moment from 'moment';
import { useDashboardStore } from '@devd-client/devd/components';
import { IoCloseOutline } from 'react-icons/io5';
import { useG2UserReview, useGetG2AccessToken } from '../api';
import G2Logo from '../../../components/src/shared/assets/g2-logo.svg';

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 24px;
`;

const WelcomeName = styled.h1`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 29px;
  width: calc(100% - 380px);
`;

const Name = styled.span``;

export function HomePage() {
  const [AppState, dispatch] = useAuth();
  const [team, setTeam] = useQueryState('team');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');

  const { data, isLoading, refetch } = useGetG2AccessToken(
    encodeURIComponent(AppState?.user?.email),
    localStorage.getItem('orgId') || ''
  );
  const { mutate: handleG2Review } = useG2UserReview();
  const [newToast] = useToastHook();
  const [reviewUrl, setReviewUrl] = useState('');
  const [isOpenReview, setIsOpenReview] = useState(false);
  const [isCheckOpen, setIsCheckOpen] = useState(false);
  const [hideReviewBtn, setHideReviewBtn] = useState(false);
  const [isLoadingFrame, setIsLoadingFrame] = useState(true);

  useEffect(() => {
    if (!isLoading && data) {
      try {
        let encodedEmail = encodeURIComponent(AppState?.user?.email);
        encodedEmail = encodedEmail.replace(/\./g, '%2E');
        const state = JSON.parse(data?.data)?.state;
        setReviewUrl(
          `https://www.g2.com/partnerships/DevDynamics/users/login.embed?state=${state}&email=${encodedEmail}`
        );
      } catch (error) {
        console.error('Invalid JSON:', error);
        // Handle invalid JSON error (ignore or show a default value)
      }
    }
  }, [AppState?.user?.email, data, isLoading, reviewUrl]);

  const handleSubmitReview = async () => {
    setHideReviewBtn(true);
    await refetch();
    setReviewUrl('');
    handelReviewSubmit();
    handleReviewClose();
  };

  const handleReviewClose = () => {
    setIsCheckOpen(false);
    setIsOpenReview(false);
    setIsLoadingFrame(true);
  };

  const handelReviewSubmit = () => {
    newToast({
      message:
        'Thank you for submitting your review! We appreciate your feedback.',
      status: 'success',
    });
    handleG2Review({
      userId: localStorage.getItem('userId'),
      orgId: localStorage.getItem('orgId'),
      email: AppState?.user?.email,
    });
  };

  const [periodOptions, setPeriodOptions] = useState<any>(durationData);

  const { isOpen } = useAiAssistantStore();

  const { selectedTeam, selected, setSelectedTeam, setSelected } =
    useDashboardStore();

  useEffect(() => {
    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'All'
        ? setTeam('All')
        : setTeam(selectedTeam.value);
    }

    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }
  }, [selectedTeam, setTeam, selected]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'All'
          ? { value: '', label: 'All' }
          : { value: team, label: team }
      );

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : AppState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(AppState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(AppState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: AppState?.user?.dto?.sprint,
          sprintName: JSON.parse(AppState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  return (
    <Flex direction="column" width="100%" minH="100%">
      <MetaTags
        title="Home | Devdynamics"
        description="Welcome to Devdynamics"
        keywords="home, devdynamics"
      />

      <HomePageContainer>
        <Box display={'flex'} w={'full'}>
          <WelcomeName>
            {getGreetingByTime()}
            {AppState?.user?.dto?.firstName && (
              <Name>,&nbsp;{AppState.user.dto.firstName}!</Name>
            )}
          </WelcomeName>
          {reviewUrl && !hideReviewBtn && (
            <Button
              backgroundColor={'transparent'}
              border={'1px'}
              borderColor={'#FF492C'}
              borderRadius={'4px'}
              paddingX={'14px'}
              paddingY={'10px'}
              fontSize={'14px'}
              fontFamily={'inter'}
              fontWeight={500}
              color={'#FF492C'}
              ml={'auto'}
              _hover={{ bg: 'transparent' }}
              onClick={() => setIsOpenReview(true)}
            >
              <Image src={G2Logo} height={'20px'} width={'20px'} mr={2} />
              Write a Review
            </Button>
          )}
        </Box>
        <Text fontFamily="heading" fontSize="sm" color="text.secondary">
          Here's what's happening in your engineering team.
        </Text>

        <Flex justify="space-between" align="flex-end">
          <TeamMenu
            selectedTeam={selectedTeam}
            handleSelectedTeam={setSelectedTeam}
            AuthActionTypes={AuthActionTypes}
            getUser={getUser}
            appState={AppState}
            dispatch={dispatch}
          />

          <DateFilter
            getUser={getUser}
            appState={AppState}
            dispatch={dispatch}
            AuthActionTypes={AuthActionTypes}
          />
        </Flex>

        <Flex gap={6} w="full">
          <Box w="full">
            {AppState?.user?.dto?.anyIntegrationDone ? (
              <></>
            ) : (
              <IntegrationOptions />
            )}
          </Box>
        </Flex>

        <Box w="full">
          <Summary team={selectedTeam.value} selected={selected} />
        </Box>

        <Box mt={4} w="full">
          <InsightCards team={selectedTeam.value} selected={selected} />
        </Box>

        {isOpen && <Chatbox />}

        {/* <Box
            mt={12}
            bg="white"
            position="fixed"
            top={0}
            height="100%"
            right={2}
            width="380px"
            zIndex="1"
            borderLeft="0.5px solid rgba(0, 0, 0, 0.1)"
          >
            <Insights />
          </Box> */}
      </HomePageContainer>

      {reviewUrl && isOpenReview && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          backgroundColor="rgba(0, 0, 0, 0.5)"
          alignItems="center"
          justifyContent="center"
          zIndex="999"
          onClick={() => setIsCheckOpen(true)}
        >
          {isLoadingFrame && (
            <Box position={'absolute'} top={'47%'} left={'49%'}>
              <Spinner />
            </Box>
          )}
          <Box
            as="iframe"
            src={reviewUrl}
            width="55%"
            height="75%"
            backgroundColor="white"
            border="none"
            borderRadius="8px"
            boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
            onLoad={() => setIsLoadingFrame(false)}
          />
          <Box
            position={'absolute'}
            top={4}
            right={4}
            cursor={'pointer'}
            onClick={() => setIsCheckOpen(true)}
          >
            <IoCloseOutline color="white" size={32} />
          </Box>
        </Flex>
      )}

      {isCheckOpen && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          alignItems="center"
          justifyContent="center"
          zIndex="1000"
        >
          <Flex
            h={'20vh'}
            w={'25vw'}
            rounded={'8px'}
            shadow={'lg'}
            gap={4}
            alignItems={'center'}
            justifyContent={'space-between'}
            flexDirection={'column'}
            padding={6}
            bg={'white'}
            fontFamily={'inter'}
          >
            <Text
              fontSize={'18px'}
              fontWeight={'semibold'}
              textAlign={'center'}
            >
              Have you submitted the review?
            </Text>
            <Flex justifyContent={'center'} gap={10} w={'full'}>
              <Button
                bg={'transparent'}
                onClick={handleSubmitReview}
                _hover={{ bg: 'gray.200' }}
                paddingX={2}
                rounded={2}
              >
                Yes
              </Button>
              <Button
                bg={'transparent'}
                onClick={handleReviewClose}
                _hover={{ bg: 'gray.200' }}
                paddingX={2}
                rounded={2}
              >
                No
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

export default HomePage;
