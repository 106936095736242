import {
  Box,
  Divider,
  Flex,
  IconButton,
  Image,
  Tag,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { splitTime } from '../../../shared/utils';
import { BsArrowRight } from 'react-icons/bs';
import { beforeStyles, hoverBeforeStyles } from '../../metricsCard.styled';
import { useCallback, useState } from 'react';
import AppTooltip from '../../../appTooltip';
import { SlideInfoDrawer } from '../../../infoSlidebar/SlideInfoDrawer';
import AiLogo from '../../../shared/assets/ai-icon-circle.svg';
import AITooltipLogo from '../../../shared/assets/ai-tooltip-logo.svg';
import { useFetchThreshold } from '../../apis';
import { useDashboardStore } from '../../../hooks/useDashboardStore';

interface MetricInfoItemsProps {
  item?: any;
  setType?: any;
  type?: any;
  clickedTab?: string;
  setClickedTab: (clickedTab: string) => void;
}

export const MetricInfoItems = ({
  item,
  setType,
  type,
  clickedTab,
  setClickedTab,
}: MetricInfoItemsProps) => {
  const { colors } = useTheme();

  const [hovered, setHovered] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerOpen = useCallback(() => {
    setOpenDrawer(true);
  }, [openDrawer]);

  const handleDrawerClose = useCallback(
    () => setOpenDrawer(false),
    [openDrawer]
  );
  const { selectedTeam } = useDashboardStore();
  const { data: goalData, isLoading: isLoadingGoalData } = useFetchThreshold(
    item?.chartKey,
    selectedTeam.value
  );

  return (
    <>
      <Box
        borderRadius="4px"
        minWidth="160px"
        cursor="pointer"
        _hover={{
          boxShadow: 'xl',
          transition: 'all 0.2s',
        }}
        border={
          clickedTab === item?.chartKey
            ? '1px solid rgba(0, 0, 0, 0.7)'
            : '1px solid #BBC7DA'
        }
        boxShadow={clickedTab === item?.chartKey ? 'xl' : 'sm'}
        transform={
          clickedTab === item?.chartKey ? 'translateY(-2px)' : 'scale(1)'
        }
        p={3}
        minHeight="100px"
        onClick={() => {
          setType(item?.chartKey);
          setClickedTab(item?.chartKey);
        }}
      >
        <Flex align={'center'} justify={'space-between'}>
          <Box
            cursor="pointer"
            position="relative"
            display="flex"
            alignItems="center"
            mb={0.5}
            fontSize="sm"
            _before={beforeStyles}
            onMouseEnter={() => setHovered((prev) => !prev)}
            onMouseLeave={() => setHovered((prev) => !prev)}
          >
            <Text
              fontFamily="heading"
              fontSize="15px"
              fontWeight="semibold"
              as="span"
              letterSpacing={0}
              transition="all 300ms linear"
              mr={hovered ? 2 : 1}
              color="text.secondary"
            >
              {item?.title}
            </Text>
            <BsArrowRight size={16} color={colors.text.secondary} />
          </Box>
          <AppTooltip
            content={
              <Text
                fontSize="sm"
                color="text.secondary"
                fontWeight="normal"
                fontFamily="heading"
              >
                {item?.info}{' '}
                <Box
                  as="span"
                  display="inline-flex"
                  gap={1}
                  alignItems="center"
                >
                  <Text
                    as="span"
                    color="blue"
                    bg="none"
                    fontSize="14"
                    display="inline"
                    borderBottom="1px solid blue"
                    fontWeight={400}
                    onClick={() => handleDrawerOpen()}
                    cursor={'pointer'}
                  >
                    Learn More by
                  </Text>
                  <Image src={AiLogo} h={5} w={5} />
                </Box>
              </Text>
            }
            placement="bottom"
            trigger="hover"
          >
            <IconButton
              aria-label="ticket/open-ai-assistant"
              icon={<Image src={AITooltipLogo} h={5} w={5} />}
              _hover={{
                transform: 'scale(1.2)',
                transition: 'transform 0.2s ease-in-out',
              }}
              variant="unstyled"
              size={'xs'}
            />
          </AppTooltip>
        </Flex>
        <Flex>
          {item?.threshold ? (
            <Text
              as="span"
              fontSize="xl"
              color="text.primary"
              fontWeight="semibold"
              fontFamily="heading"
            >
              {item?.threshold === null
                ? '_'
                : item?.threshold?.value === 'NaN'
                ? '_'
                : item?.threshold?.value === null
                ? '_'
                : item?.chartKey === 'PR_CYCLE_TIME_BREAKDOWN' ||
                  item?.chartKey === 'LEAD_TIME_BREAKDOWN' ||
                  item?.chartKey === 'MEAN_TIME_TO_RECOVERY_DISTRIBUTION'
                ? splitTime(parseFloat(item?.threshold?.value))
                : String(parseFloat(item?.threshold?.value).toFixed(1))}
            </Text>
          ) : (
            <Text
              as="span"
              fontSize="xl"
              color="text.primary"
              fontWeight="semibold"
              fontFamily="heading"
            >
              _
            </Text>
          )}

          <Text
            mt={2.5}
            as="span"
            fontSize="xxs"
            color="text.secondary"
            fontWeight="semibold"
            fontFamily="heading"
            ml={1}
          >
            {item?.threshold?.title}
          </Text>
        </Flex>
        {item?.threshold?.target && (
          <>
            <Box
              mb={2}
              height="4px"
              width="full"
              bg={item?.threshold?.color}
              borderBottomWidth={0}
            />
            <Flex width={'full'} justify="space-between">
              <Text
                fontSize="xs"
                color="text.secondary"
                fontWeight="normal"
                fontFamily="heading"
              >
                Goal: {item?.threshold?.target}
              </Text>
              {item?.threshold?.tag && (
                <Tag
                  size="sm"
                  bg="gray.100"
                  fontSize="xxs"
                  fontFamily="heading"
                >
                  {item?.threshold?.tag}
                </Tag>
              )}
            </Flex>
          </>
        )}
      </Box>
      {openDrawer && (
        <SlideInfoDrawer
          handelDrawerClose={handleDrawerClose}
          openDrawer={openDrawer}
          title={item?.title}
          chartKey={item?.chartKey}
          item={item}
          goalData={goalData}
        />
      )}
    </>
  );
};
