import { gql } from 'graphql-request';
import { graphQLClient } from '@devd-client/api';

export const prChartsGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  filter: {
    teamId?: string;
    author?: string;
    type?: string;
    projectsFilter?: string[];
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetPrReportDetail(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metric: String!
        $teamId: String
        $author: String
        $sprintId: String
        $granularity: String!
        $pageNumber: Int!
        $pageSize: Int!
        $projectsFilter: [String]
      ) {
        PrReportDetail(
          metric: $metric
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            projectsFilter: $projectsFilter
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            id
            link
            author
            summary
            repository
            changes
            issueId
            date
            updatedAt
            attributes {
              key
              value
            }
            title
            externalLink
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      metric,
      ...filter,
      granularity: '',
      pageNumber,
      pageSize,
    }
  );

export const PRChartsGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  filter: {
    teamId?: string;
    author?: string;
    projectsFilter?: string[];
    sprintId?: string;
    type?: string;
    status?: string;
    prSize?: string;
    orderBy?: string;
    orderColumn?: string;
    reviewer?: string;
    filterAge?: string;
    name?: string;
    prReviewResponsivenessType?: string;
    prFeedbackResponsivenessType?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> => {
  return filter?.name === 'Review Responsiveness Index'
    ? await graphQLClient.request(
        gql`
          query GetPRReportDetail(
            $orgId: String
            $startDate: String!
            $endDate: String
            $metric: String!
            $teamId: String
            $reviewer: String
            $author: String
            $sprintId: String
            $granularity: String!
            $pageNumber: Int!
            $pageSize: Int!
            $projectsFilter: [String]
            $type: String!
            $status: String
            $prSize: String
            $orderBy: String
            $orderColumn: String
            $filterAge: String
            $prReviewResponsivenessType: String
          ) {
            PRReportDetail(
              metric: $metric
              granularity: $granularity
              filter: {
                startDate: $startDate
                endDate: $endDate
                orgId: $orgId
                teamId: $teamId
                author: $author
                reviewer: $reviewer
                sprintId: $sprintId
                project: ""
                projectsFilter: $projectsFilter
                type: $type
                status: $status
                prViewFilters: {
                  prReviewResponsivenessType: $prReviewResponsivenessType
                  prSize: $prSize
                  age: $filterAge
                }
              }
              orderBy: $orderBy
              orderColumn: $orderColumn
              pageNumber: $pageNumber
              pageSize: $pageSize
            ) {
              data {
                status
                prDetail {
                  id
                  externalUrl
                  repo
                  title
                  issueId
                  addition
                  deletion
                  branch
                  batchSize
                }
                teams
                author {
                  avatar
                  name
                  commitCount
                }
                createdAt
                mergedAt
                reviewersDetails {
                  contributors {
                    avatar
                    name
                    commitCount
                  }
                  approvedBy {
                    avatar
                    name
                    commitCount
                  }
                  comments
                }
                prCycleTime {
                  color
                  time
                }
                breakdowns {
                  title
                  icon
                  value
                }
              }
              totalPages
              currentPage
              totalCount
            }
          }
        `,
        {
          orgId: localStorage.getItem('orgId'),
          startDate,
          endDate,
          metric,
          ...filter,
          granularity: '',
          pageNumber,
          pageSize,
        }
      )
    : filter?.name === 'Feedback Responsiveness Index'
    ? await graphQLClient.request(
        gql`
          query GetPRReportDetail(
            $orgId: String
            $startDate: String!
            $endDate: String
            $metric: String!
            $teamId: String
            $reviewer: String
            $author: String
            $sprintId: String
            $granularity: String!
            $pageNumber: Int!
            $pageSize: Int!
            $projectsFilter: [String]
            $type: String!
            $status: String
            $prSize: String
            $orderBy: String
            $orderColumn: String
            $filterAge: String
            $prFeedbackResponsivenessType: String
          ) {
            PRReportDetail(
              metric: $metric
              granularity: $granularity
              filter: {
                startDate: $startDate
                endDate: $endDate
                orgId: $orgId
                teamId: $teamId
                author: $author
                reviewer: $reviewer
                sprintId: $sprintId
                project: ""
                projectsFilter: $projectsFilter
                type: $type
                status: $status
                prViewFilters: {
                  prFeedbackResponsivenessType: $prFeedbackResponsivenessType
                  prSize: $prSize
                  age: $filterAge
                }
              }
              orderBy: $orderBy
              orderColumn: $orderColumn
              pageNumber: $pageNumber
              pageSize: $pageSize
            ) {
              data {
                status
                prDetail {
                  id
                  externalUrl
                  repo
                  title
                  issueId
                  addition
                  deletion
                  branch
                  batchSize
                }
                teams
                author {
                  avatar
                  name
                  commitCount
                }
                createdAt
                mergedAt
                reviewersDetails {
                  contributors {
                    avatar
                    name
                    commitCount
                  }
                  approvedBy {
                    avatar
                    name
                    commitCount
                  }
                  comments
                }
                prCycleTime {
                  color
                  time
                }
                breakdowns {
                  title
                  icon
                  value
                }
              }
              totalPages
              currentPage
              totalCount
            }
          }
        `,
        {
          orgId: localStorage.getItem('orgId'),
          startDate,
          endDate,
          metric,
          ...filter,
          granularity: '',
          pageNumber,
          pageSize,
        }
      )
    : await graphQLClient.request(
        gql`
          query GetPRReportDetail(
            $orgId: String
            $startDate: String!
            $endDate: String
            $metric: String!
            $teamId: String
            $reviewer: String
            $author: String
            $sprintId: String
            $granularity: String!
            $pageNumber: Int!
            $pageSize: Int!
            $projectsFilter: [String]
            $type: String!
            $status: String
            $prSize: String
            $orderBy: String
            $orderColumn: String
            $filterAge: String
          ) {
            PRReportDetail(
              metric: $metric
              granularity: $granularity
              filter: {
                startDate: $startDate
                endDate: $endDate
                orgId: $orgId
                teamId: $teamId
                author: $author
                reviewer: $reviewer
                sprintId: $sprintId
                project: ""
                projectsFilter: $projectsFilter
                type: $type
                status: $status
                prViewFilters: { prSize: $prSize, age: $filterAge }
              }
              orderBy: $orderBy
              orderColumn: $orderColumn
              pageNumber: $pageNumber
              pageSize: $pageSize
            ) {
              data {
                status
                prDetail {
                  id
                  externalUrl
                  repo
                  title
                  issueId
                  addition
                  deletion
                  branch
                  batchSize
                }
                teams
                author {
                  avatar
                  name
                  commitCount
                }
                createdAt
                mergedAt
                reviewersDetails {
                  contributors {
                    avatar
                    name
                    commitCount
                  }
                  approvedBy {
                    avatar
                    name
                    commitCount
                  }
                  comments
                }
                prCycleTime {
                  color
                  time
                }
                breakdowns {
                  title
                  icon
                  value
                }
              }
              totalPages
              currentPage
              totalCount
            }
          }
        `,
        {
          orgId: localStorage.getItem('orgId'),
          startDate,
          endDate,
          metric,
          ...filter,
          granularity: '',
          pageNumber,
          pageSize,
        }
      );
};

export const issueChartsGraphql = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    teamId?: string;
    author?: string;
    type?: string;
    status?: string;
    sprintId?: string;
    projectsFilter?: string[];
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetIssueReportDetail(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metricType: String!
        $granularity: String!
        $teamId: String
        $sprintId: String
        $author: String
        $type: String
        $status: String
        $pageNumber: Int!
        $pageSize: Int!
        $projectsFilter: [String]
      ) {
        IssueReportDetail(
          metric: $metricType
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            sprintId: $sprintId
            author: $author
            status: $status
            type: $type
            projectsFilter: $projectsFilter
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            title
            subTitle
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const changeFailureGraphql = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: { teamId?: string; author?: string; sprintId?: string },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetChangeFailureDetail(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metricType: String!
        $granularity: String!
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        ChangeFailureDetail(
          metric: $metricType
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            title
            subTitle
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const DeploymentDetails = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    teamId?: string;
    author?: string;
    sprintId?: string;
    projectsFilter?: string[];
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetDeploymentDetails(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metricType: String!
        $granularity: String!
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
        $projectsFilter: [String]
      ) {
        DeploymentDetails(
          metric: $metricType
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            projectsFilter: $projectsFilter
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            title
            subTitle
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const WellbeingDetails = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: { teamId?: string; author?: string; sprintId?: string },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query WellBeingDetailsByType(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metricType: String!
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        WellBeingDetailsByType(
          metric: $metricType
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            name
            email
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const QualityDetails = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    teamId?: string;
    author?: string;
    sprintId?: string;
    projectsFilter?: string[];
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query QualityBranchDetailByType(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metricType: String!
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
        $projectsFilter: [String]
      ) {
        QualityBranchDetailByType(
          metric: $metricType
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            projectsFilter: $projectsFilter
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            analysisId
            project
            branch
            date
            value
            expectedValue
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const VulnerabilityAlertsDetails = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: { teamId?: string; author?: string; sprintId?: string },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query VulnerabilityAlertsDetail(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metricType: String!
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        VulnerabilityAlertsDetail(
          metric: $metricType
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            id
            summary
            repo
            package_name
            status
            severity
            affected_version
            patched_version
            updated_at
            link
          }
          totalPages
          currentPage
          totalCount
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const GoalMetricDetailsGit = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: { teamId?: string; author?: string; sprintId?: string },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetGoalMetricDetailsGit(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metricType: String!
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        GoalMetricDetailsGit(
          metric: $metricType
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
          isMeet: false
        ) {
          data {
            id
            link
            author
            summary
            repository
            changes
            issueId
            date
            attributes {
              key
              value
            }
            title
            externalLink
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const GoalMetricDetailsJira = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: { teamId?: string; author?: string; sprintId?: string },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetGoalMetricDetailsJira(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metricType: String!
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        GoalMetricDetailsJira(
          metric: $metricType
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
          isMeet: false
        ) {
          data {
            title
            subTitle
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const getContributorGraphql = async (
  startDate: string,
  endDate: string,
  filter: {
    teamId?: string;
    author?: string;
    projectsFilter?: string[];
    sprintId?: string;
  }
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetContributorDetails(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $sprintId: String
        $projectsFilter: [String]
      ) {
        ContributorDetails(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            projectsFilter: $projectsFilter
            sprintId: $sprintId
          }
        ) {
          data {
            name
            email
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      ...filter,
    }
  );
// author
export const workingAgreementGraphql = async (
  startDate: string,
  endDate: string,
  filter: { teamId?: string; author?: string; sprintId?: string }
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetTeamStatsWorkingAgreementDetails(
        $orgId: String
        $startDate: String!
        $endDate: String
        $author: String!
        $sprintId: String
      ) {
        TeamStatsWorkingAgreementDetails(
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            author: $author
            sprintId: $sprintId
          }
        ) {
          data {
            id
            assignee
            updatedDate
            link
            ticketType
            summary
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      ...filter,
    }
  );
// author
export const commitGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  filter: { teamId?: string; author?: string; sprintId?: string },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetCommitReportDetail(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metric: String!
        $author: String!
        $teamId: String!
        $sprintId: String
        $granularity: String!
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        CommitReportDetail(
          metric: $metric
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            author: $author
            sprintId: $sprintId
            project: ""
            teamId: $teamId
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            link
            author
            summary
            repository
            date
            attributes {
              key
              value
            }
            title
            externalLink
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      metric,
      ...filter,
      granularity: '',
      pageNumber,
      pageSize,
    }
  );

// TeamId
export const memberCountGraphql = async (filter: {
  teamId?: string;
  author?: string;
  sprintId?: string;
}): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetTeamMembers(
        $orgId: String
        $teamId: String!
        $sprintId: String
      ) {
        TeamMembers(
          filter: { orgId: $orgId, teamId: $teamId, sprintId: $sprintId }
        ) {
          data {
            name
            role
            email
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      ...filter,
    }
  );

export const customMetricsGraphql = async (
  startDate: string,
  endDate: string,
  customFieldName: string,
  filter: {
    teamId?: string;
    author?: string;
    type?: string;
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query getCustomMetricDetailJira(
        $orgId: String
        $startDate: String!
        $endDate: String
        $customFieldName: String!
        $granularity: String!
        $teamId: String
        $author: String
        $sprintId: String
        $type: String
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        CustomMetricDetailJira(
          customFieldName: $customFieldName
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            type: $type
            project: ""
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            title
            subTitle
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      customFieldName,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const customBuildMetricGraphql = async (
  startDate: string,
  endDate: string,
  customFieldName: string,
  filter: {
    teamId?: string;
    author?: string;
    type?: string;
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetBuildDetailCustom(
        $orgId: String
        $startDate: String!
        $endDate: String
        $customFieldName: String!
        $granularity: String!
        $teamId: String
        $sprintId: String
        $author: String
        $type: String
        $status: String
        $pageNumber: Int!
        $pageSize: Int!
        $projectsFilter: [String]
      ) {
        BuildDetailCustom(
          customFieldName: $customFieldName
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            sprintId: $sprintId
            author: $author
            status: $status
            type: $type
            projectsFilter: $projectsFilter
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            id
            type
            status
            environment
            commit
            repo
            externalUrl
            updatedAt
            project
            buildTime
            pipelineName
            artifact
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      customFieldName,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );
export const customIssueMetricGraphql = async (
  startDate: string,
  endDate: string,
  customFieldName: string,
  filter: {
    teamId?: string;
    author?: string;
    type?: string;
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetIssueDetailCustom(
        $orgId: String
        $startDate: String!
        $endDate: String
        $customFieldName: String!
        $granularity: String!
        $teamId: String
        $sprintId: String
        $author: String
        $type: String
        $status: String
        $pageNumber: Int!
        $pageSize: Int!
        $projectsFilter: [String]
      ) {
        IssueDetailCustom(
          customFieldName: $customFieldName
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            sprintId: $sprintId
            author: $author
            status: $status
            type: $type
            projectsFilter: $projectsFilter
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            title
            subTitle
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      customFieldName,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const issueAgeingDetailsGraphql = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    teamId?: string;
    author?: string;
    age?: string;
    type?: string;
    sprintId?: string;
    projectsFilter?: string[];
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetIssueDetailsForAge(
        $orgId: String
        $startDate: String
        $endDate: String
        $teamId: String
        $author: String
        $sprintId: String
        $type: String
        $pageNumber: Int!
        $pageSize: Int!
        $age: String!
        $metricType: String!
        $projectsFilter: [String]
      ) {
        IssueDetailsForAge(
          metric: $metricType
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            type: $type
            projectsFilter: $projectsFilter
          }
          age: $age
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            title
            subTitle
            priority
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const requirementStabilityDetailsGraphql = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    teamId?: string;
    author?: string;
    type?: string;
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetRequirementStabilityDetails(
        $orgId: String
        $startDate: String
        $endDate: String
        $teamId: String
        $author: String
        $sprintId: String
        $type: String
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        RequirementStabilityDetails(
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            type: $type
            project: ""
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            title
            subTitle
            priority
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const PrAgeingDetailsGraphql = async (
  startDate: string,
  endDate: string,
  filter: {
    teamId?: string;
    author?: string;
    age?: string;
    projectsFilter?: string[];
    sprintId?: string;
  }
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetPrAgeingDetails(
        $orgId: String
        $startDate: String
        $endDate: String
        $teamId: String
        $author: String
        $sprintId: String
        $age: String!
        $projectsFilter: [String]
      ) {
        PrAgeingDetails(
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            projectsFilter: $projectsFilter
          }
          age: $age
        ) {
          data {
            title
            subTitle
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      granularity: '',
      ...filter,
    }
  );

export const PRAgeingDetailsGraphql = async (
  startDate: string,
  endDate: string,
  filter: {
    teamId?: string;
    author?: string;
    age?: string;
    projectsFilter?: string[];
    sprintId?: string;
    type?: string;
    status?: string;
    prSize?: string;
    orderBy?: string;
    orderColumn?: string;
    filterAge?: string;
    reviewer?: string;
    name?: string;
    prReviewResponsivenessType?: string;
    prFeedbackResponsivenessType?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> => {
  return filter?.name === 'Review Responsiveness Index'
    ? await graphQLClient.request(
        gql`
          query GetPRAgeingDetails(
            $orgId: String
            $startDate: String
            $endDate: String
            $teamId: String
            $author: String
            $sprintId: String
            $age: String!
            $projectsFilter: [String]
            $pageNumber: Int!
            $pageSize: Int!
            $type: String
            $status: String
            $prSize: String
            $orderBy: String
            $orderColumn: String
            $filterAge: String
            $reviewer: String
            $prReviewResponsivenessType: String
          ) {
            PRAgeingDetails(
              filter: {
                startDate: $startDate
                endDate: $endDate
                orgId: $orgId
                teamId: $teamId
                author: $author
                sprintId: $sprintId
                project: ""
                projectsFilter: $projectsFilter
                type: $type
                status: $status
                reviewer: $reviewer
                prViewFilters: {
                  prReviewResponsivenessType: $prReviewResponsivenessType
                  prSize: $prSize
                  age: $filterAge
                }
              }
              orderBy: $orderBy
              orderColumn: $orderColumn
              age: $age
              pageNumber: $pageNumber
              pageSize: $pageSize
            ) {
              data {
                prDetail {
                  id
                  externalUrl
                  title
                  repo
                  issueId
                  addition
                  deletion
                  branch
                  batchSize
                }
                teams
                author {
                  avatar
                  name
                  commitCount
                }
                createdAt
                mergedAt
                reviewersDetails {
                  contributors {
                    avatar
                    name
                    commitCount
                  }
                  approvedBy {
                    avatar
                    name
                    commitCount
                  }
                  comments
                }
                prCycleTime {
                  color
                  time
                }
                breakdowns {
                  title
                  icon
                  value
                }
              }
              totalPages
              currentPage
              totalCount
            }
          }
        `,
        {
          orgId: `${localStorage.getItem('orgId')}`,
          startDate,
          endDate,
          granularity: '',
          pageNumber,
          pageSize,
          ...filter,
        }
      )
    : filter?.name === 'Feedback Responsiveness Index'
    ? await graphQLClient.request(
        gql`
          query GetPRAgeingDetails(
            $orgId: String
            $startDate: String
            $endDate: String
            $teamId: String
            $author: String
            $sprintId: String
            $age: String!
            $projectsFilter: [String]
            $pageNumber: Int!
            $pageSize: Int!
            $type: String
            $status: String
            $prSize: String
            $orderBy: String
            $orderColumn: String
            $filterAge: String
            $reviewer: String
            $prFeedbackResponsivenessType: String
          ) {
            PRAgeingDetails(
              filter: {
                startDate: $startDate
                endDate: $endDate
                orgId: $orgId
                teamId: $teamId
                author: $author
                sprintId: $sprintId
                project: ""
                projectsFilter: $projectsFilter
                type: $type
                status: $status
                reviewer: $reviewer
                prViewFilters: {
                  prFeedbackResponsivenessType: $prFeedbackResponsivenessType
                  prSize: $prSize
                  age: $filterAge
                }
              }
              orderBy: $orderBy
              orderColumn: $orderColumn
              age: $age
              pageNumber: $pageNumber
              pageSize: $pageSize
            ) {
              data {
                prDetail {
                  id
                  externalUrl
                  title
                  repo
                  issueId
                  addition
                  deletion
                  branch
                  batchSize
                }
                teams
                author {
                  avatar
                  name
                  commitCount
                }
                createdAt
                mergedAt
                reviewersDetails {
                  contributors {
                    avatar
                    name
                    commitCount
                  }
                  approvedBy {
                    avatar
                    name
                    commitCount
                  }
                  comments
                }
                prCycleTime {
                  color
                  time
                }
                breakdowns {
                  title
                  icon
                  value
                }
              }
              totalPages
              currentPage
              totalCount
            }
          }
        `,
        {
          orgId: `${localStorage.getItem('orgId')}`,
          startDate,
          endDate,
          granularity: '',
          pageNumber,
          pageSize,
          ...filter,
        }
      )
    : await graphQLClient.request(
        gql`
          query GetPRAgeingDetails(
            $orgId: String
            $startDate: String
            $endDate: String
            $teamId: String
            $author: String
            $sprintId: String
            $age: String!
            $projectsFilter: [String]
            $pageNumber: Int!
            $pageSize: Int!
            $type: String
            $status: String
            $prSize: String
            $orderBy: String
            $orderColumn: String
            $filterAge: String
            $reviewer: String
          ) {
            PRAgeingDetails(
              filter: {
                startDate: $startDate
                endDate: $endDate
                orgId: $orgId
                teamId: $teamId
                author: $author
                sprintId: $sprintId
                project: ""
                projectsFilter: $projectsFilter
                type: $type
                status: $status
                reviewer: $reviewer
                prViewFilters: { prSize: $prSize, age: $filterAge }
              }
              orderBy: $orderBy
              orderColumn: $orderColumn
              age: $age
              pageNumber: $pageNumber
              pageSize: $pageSize
            ) {
              data {
                prDetail {
                  id
                  externalUrl
                  title
                  repo
                  issueId
                  addition
                  deletion
                  branch
                  batchSize
                }
                teams
                author {
                  avatar
                  name
                  commitCount
                }
                createdAt
                mergedAt
                reviewersDetails {
                  contributors {
                    avatar
                    name
                    commitCount
                  }
                  approvedBy {
                    avatar
                    name
                    commitCount
                  }
                  comments
                }
                prCycleTime {
                  color
                  time
                }
                breakdowns {
                  title
                  icon
                  value
                }
              }
              totalPages
              currentPage
              totalCount
            }
          }
        `,
        {
          orgId: `${localStorage.getItem('orgId')}`,
          startDate,
          endDate,
          granularity: '',
          pageNumber,
          pageSize,
          ...filter,
        }
      );
};

export const prReviewResponsivenessDetailsGraphql = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    teamId?: string;
    author?: string;
    name?: string;
    projectsFilter?: string[];
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetPrReviewResponsivenessDetails(
        $orgId: String
        $startDate: String
        $endDate: String
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
        $granularity: String!
        $name: String!
        $projectsFilter: [String]
      ) {
        PrReviewResponsivenessDetails(
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            projectsFilter: $projectsFilter
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
          name: $name
          granularity: $granularity
        ) {
          data {
            title
            subTitle
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const PRReviewResponsivenessDetailsGraphql = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    name?: string;
    teamId?: string;
    author?: string;
    projectsFilter?: string[];
    sprintId?: string;
    type?: string;
    status?: string;
    prSize?: string;
    orderBy?: string;
    orderColumn?: string;
    metricKey?: string;
    prReviewResponsivenessType?: string;
    prFeedbackResponsivenessType?: string;
    filterAge?: string;
    reviewer?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> => {
  return filter?.name === 'Review Responsiveness Index'
    ? await graphQLClient.request(
        gql`
          query GetPRReviewResponsivenessDetails(
            $orgId: String
            $startDate: String
            $endDate: String
            $teamId: String
            $author: String
            $sprintId: String
            $pageNumber: Int!
            $pageSize: Int!
            $granularity: String!
            $name: String!
            $projectsFilter: [String]
            $prSize: String
            $status: String
            $prReviewResponsivenessType: String
            $filterAge: String
            $reviewer: String
            $type: String
          ) {
            PRReviewResponsivenessDetails(
              filter: {
                startDate: $startDate
                endDate: $endDate
                orgId: $orgId
                teamId: $teamId
                author: $author
                sprintId: $sprintId
                project: ""
                projectsFilter: $projectsFilter
                reviewer: $reviewer
                type: $type
                status: $status
                prViewFilters: {
                  prReviewResponsivenessType: $prReviewResponsivenessType
                  prSize: $prSize
                  age: $filterAge
                }
              }
              pageNumber: $pageNumber
              pageSize: $pageSize
              name: $name
              granularity: $granularity
            ) {
              data {
                status
                prDetail {
                  id
                  externalUrl
                  repo
                  title
                  issueId
                  addition
                  deletion
                  branch
                  batchSize
                }
                teams
                author {
                  avatar
                  name
                  commitCount
                }
                createdAt
                mergedAt
                reviewersDetails {
                  contributors {
                    avatar
                    name
                    commitCount
                  }
                  approvedBy {
                    avatar
                    name
                    commitCount
                  }
                  comments
                  comments
                }
                prCycleTime {
                  color
                  time
                }
                breakdowns {
                  title
                  icon
                  value
                }
              }
              totalPages
              currentPage
              totalCount
            }
          }
        `,
        {
          orgId: localStorage.getItem('orgId'),
          startDate,
          endDate,
          granularity: '',
          ...filter,
          pageNumber,
          pageSize,
        }
      )
    : filter?.name === 'Review Completion'
    ? await graphQLClient.request(
        gql`
          query GetPRReviewResponsivenessDetails(
            $orgId: String
            $startDate: String
            $endDate: String
            $teamId: String
            $author: String
            $sprintId: String
            $pageNumber: Int!
            $pageSize: Int!
            $granularity: String!
            $name: String!
            $projectsFilter: [String]
            $prSize: String
            $filterAge: String
            $reviewer: String
            $type: String
            $status: String
          ) {
            PRReviewResponsivenessDetails(
              filter: {
                startDate: $startDate
                endDate: $endDate
                orgId: $orgId
                teamId: $teamId
                author: $author
                sprintId: $sprintId
                project: ""
                type: $type
                reviewer: $reviewer
                status: $status
                projectsFilter: $projectsFilter
                prViewFilters: { prSize: $prSize, age: $filterAge }
              }
              pageNumber: $pageNumber
              pageSize: $pageSize
              name: $name
              granularity: $granularity
            ) {
              data {
                status
                prDetail {
                  id
                  externalUrl
                  repo
                  title
                  issueId
                  addition
                  deletion
                  branch
                  batchSize
                }
                teams
                author {
                  avatar
                  name
                  commitCount
                }
                createdAt
                mergedAt
                reviewersDetails {
                  contributors {
                    avatar
                    name
                    commitCount
                  }
                  approvedBy {
                    avatar
                    name
                    commitCount
                  }
                  comments
                  comments
                }
                prCycleTime {
                  color
                  time
                }
                breakdowns {
                  title
                  icon
                  value
                }
              }
              totalPages
              currentPage
              totalCount
            }
          }
        `,
        {
          orgId: localStorage.getItem('orgId'),
          startDate,
          endDate,
          granularity: '',
          ...filter,
          pageNumber,
          pageSize,
        }
      )
    : await graphQLClient.request(
        gql`
          query GetPRReviewResponsivenessDetails(
            $orgId: String
            $startDate: String
            $endDate: String
            $teamId: String
            $author: String
            $sprintId: String
            $pageNumber: Int!
            $pageSize: Int!
            $granularity: String!
            $name: String!
            $prSize: String
            $projectsFilter: [String]
            $prFeedbackResponsivenessType: String
            $filterAge: String
            $reviewer: String
            $type: String
            $status: String
          ) {
            PRReviewResponsivenessDetails(
              filter: {
                startDate: $startDate
                endDate: $endDate
                orgId: $orgId
                teamId: $teamId
                author: $author
                sprintId: $sprintId
                project: ""
                projectsFilter: $projectsFilter
                reviewer: $reviewer
                type: $type
                status: $status
                prViewFilters: {
                  prFeedbackResponsivenessType: $prFeedbackResponsivenessType
                  prSize: $prSize
                  age: $filterAge
                }
              }
              pageNumber: $pageNumber
              pageSize: $pageSize
              name: $name
              granularity: $granularity
            ) {
              data {
                status
                prDetail {
                  id
                  externalUrl
                  repo
                  title
                  issueId
                  addition
                  deletion
                  branch
                  batchSize
                }
                teams
                author {
                  avatar
                  name
                  commitCount
                }
                createdAt
                mergedAt
                reviewersDetails {
                  contributors {
                    avatar
                    name
                    commitCount
                  }
                  approvedBy {
                    avatar
                    name
                    commitCount
                  }
                  comments
                  comments
                }
                prCycleTime {
                  color
                  time
                }
                breakdowns {
                  title
                  icon
                  value
                }
              }
              totalPages
              currentPage
              totalCount
            }
          }
        `,
        {
          orgId: localStorage.getItem('orgId'),
          startDate,
          endDate,
          granularity: '',
          ...filter,
          pageNumber,
          pageSize,
        }
      );
};

export const prHealthIndexGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  filter: {
    teamId?: string;
    author?: string;
    type?: string;
    projectsFilter?: string[];
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetPrHealthIndexesDetails(
        $orgId: String
        $startDate: String!
        $endDate: String
        $teamId: String
        $author: String
        $sprintId: String
        $granularity: String!
        $pageNumber: Int!
        $pageSize: Int!
        $type: String!
        $projectsFilter: [String]
      ) {
        PrHealthIndexesDetails(
          metricKey: $type
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            projectsFilter: $projectsFilter
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            id
            link
            author
            summary
            repository
            changes
            issueId
            date
            attributes {
              key
              value
            }
            title
            externalLink
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      ...filter,
      granularity: '',
      pageNumber,
      pageSize,
    }
  );

export const PRHealthIndexGraphql = async (
  startDate: string,
  endDate: string,
  type: string,
  filter: {
    teamId?: string;
    author?: string;
    projectsFilter?: string[];
    sprintId?: string;
    type?: string;
    status?: string;
    prSize?: string;
    orderBy?: string;
    orderColumn?: string;
    metricKey?: string;
    filterAge?: string;
    reviewer?: string;
    name?: string;
    prReviewResponsivenessType?: string;
    prFeedbackResponsivenessType?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> => {
  return filter?.name === 'Review Responsiveness Index'
    ? await graphQLClient.request(
        gql`
          query GetPRHealthIndexesDetails(
            $orgId: String
            $startDate: String!
            $endDate: String
            $metricKey: String!
            $teamId: String
            $author: String
            $sprintId: String
            $granularity: String!
            $pageNumber: Int!
            $pageSize: Int!
            $projectsFilter: [String]
            $type: String!
            $status: String
            $prSize: String
            $orderBy: String
            $orderColumn: String
            $filterAge: String
            $reviewer: String
            $prReviewResponsivenessType: String
          ) {
            PRHealthIndexesDetails(
              metricKey: $metricKey
              granularity: $granularity
              filter: {
                startDate: $startDate
                endDate: $endDate
                orgId: $orgId
                teamId: $teamId
                author: $author
                sprintId: $sprintId
                project: ""
                projectsFilter: $projectsFilter
                type: $type
                status: $status
                reviewer: $reviewer
                prViewFilters: {
                  prReviewResponsivenessType: $prReviewResponsivenessType
                  prSize: $prSize
                  age: $filterAge
                }
              }
              orderBy: $orderBy
              orderColumn: $orderColumn
              pageNumber: $pageNumber
              pageSize: $pageSize
            ) {
              data {
                status
                prDetail {
                  id
                  externalUrl
                  repo
                  title
                  issueId
                  addition
                  deletion
                  branch
                  batchSize
                }
                teams
                author {
                  avatar
                  name
                  commitCount
                }
                createdAt
                mergedAt
                reviewersDetails {
                  contributors {
                    avatar
                    name
                    commitCount
                  }
                  approvedBy {
                    avatar
                    name
                    commitCount
                  }
                  comments
                }
                prCycleTime {
                  color
                  time
                }
                breakdowns {
                  title
                  icon
                  value
                }
              }
              totalPages
              currentPage
              totalCount
            }
          }
        `,
        {
          orgId: `${localStorage.getItem('orgId')}`,
          startDate,
          endDate,
          granularity: '',
          pageNumber,
          pageSize,
          ...filter,
        }
      )
    : filter?.name === 'Feedback Responsiveness Index'
    ? await graphQLClient.request(
        gql`
          query GetPRHealthIndexesDetails(
            $orgId: String
            $startDate: String!
            $endDate: String
            $metricKey: String!
            $teamId: String
            $author: String
            $sprintId: String
            $granularity: String!
            $pageNumber: Int!
            $pageSize: Int!
            $projectsFilter: [String]
            $type: String!
            $status: String
            $prSize: String
            $orderBy: String
            $orderColumn: String
            $filterAge: String
            $reviewer: String
            $prFeedbackResponsivenessType: String
          ) {
            PRHealthIndexesDetails(
              metricKey: $metricKey
              granularity: $granularity
              filter: {
                startDate: $startDate
                endDate: $endDate
                orgId: $orgId
                teamId: $teamId
                author: $author
                sprintId: $sprintId
                project: ""
                projectsFilter: $projectsFilter
                type: $type
                status: $status
                reviewer: $reviewer
                prViewFilters: {
                  prFeedbackResponsivenessType: $prFeedbackResponsivenessType
                  prSize: $prSize
                  age: $filterAge
                }
              }
              orderBy: $orderBy
              orderColumn: $orderColumn
              pageNumber: $pageNumber
              pageSize: $pageSize
            ) {
              data {
                status
                prDetail {
                  id
                  externalUrl
                  repo
                  title
                  issueId
                  addition
                  deletion
                  branch
                  batchSize
                }
                teams
                author {
                  avatar
                  name
                  commitCount
                }
                createdAt
                mergedAt
                reviewersDetails {
                  contributors {
                    avatar
                    name
                    commitCount
                  }
                  approvedBy {
                    avatar
                    name
                    commitCount
                  }
                  comments
                }
                prCycleTime {
                  color
                  time
                }
                breakdowns {
                  title
                  icon
                  value
                }
              }
              totalPages
              currentPage
              totalCount
            }
          }
        `,
        {
          orgId: `${localStorage.getItem('orgId')}`,
          startDate,
          endDate,
          granularity: '',
          pageNumber,
          pageSize,
          ...filter,
        }
      )
    : await graphQLClient.request(
        gql`
          query GetPRHealthIndexesDetails(
            $orgId: String
            $startDate: String!
            $endDate: String
            $metricKey: String!
            $teamId: String
            $author: String
            $sprintId: String
            $granularity: String!
            $pageNumber: Int!
            $pageSize: Int!
            $projectsFilter: [String]
            $type: String!
            $status: String
            $prSize: String
            $orderBy: String
            $orderColumn: String
            $filterAge: String
            $reviewer: String
          ) {
            PRHealthIndexesDetails(
              metricKey: $metricKey
              granularity: $granularity
              filter: {
                startDate: $startDate
                endDate: $endDate
                orgId: $orgId
                teamId: $teamId
                author: $author
                sprintId: $sprintId
                project: ""
                projectsFilter: $projectsFilter
                type: $type
                status: $status
                reviewer: $reviewer
                prViewFilters: { prSize: $prSize, age: $filterAge }
              }
              orderBy: $orderBy
              orderColumn: $orderColumn
              pageNumber: $pageNumber
              pageSize: $pageSize
            ) {
              data {
                status
                prDetail {
                  id
                  externalUrl
                  repo
                  title
                  issueId
                  addition
                  deletion
                  branch
                  batchSize
                }
                teams
                author {
                  avatar
                  name
                  commitCount
                }
                createdAt
                mergedAt
                reviewersDetails {
                  contributors {
                    avatar
                    name
                    commitCount
                  }
                  approvedBy {
                    avatar
                    name
                    commitCount
                  }
                  comments
                }
                prCycleTime {
                  color
                  time
                }
                breakdowns {
                  title
                  icon
                  value
                }
              }
              totalPages
              currentPage
              totalCount
            }
          }
        `,
        {
          orgId: `${localStorage.getItem('orgId')}`,
          startDate,
          endDate,
          granularity: '',
          pageNumber,
          pageSize,
          ...filter,
        }
      );
};

export const locVsPrCycleTimeDetailsGraphql = async (
  startDate: string,
  endDate: string,
  filter: {
    teamId?: string;
    sprintId?: string;
    lowerBoundIncl?: number;
    upperBound?: number;
  }
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetLocVsPrCycleTimeDetails(
        $orgId: String
        $startDate: String!
        $endDate: String
        $teamId: String
        $sprintId: String
        $lowerBoundIncl: Int!
        $upperBound: Int
      ) {
        LocVsPrCycleTimeDetails(
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            sprintId: $sprintId
          }
          lowerBoundIncl: $lowerBoundIncl
          upperBound: $upperBound
        ) {
          data {
            id
            link
            author
            summary
            repository
            createdAt
            updatedAt
            mergedAt
            status
            commentCount
            changes
            addition
            deletions
            files
            issueId
            prCycleTime
            codingTime
            pickupTime
            mergeTime
            reviewers
            title
            subTitle
            date
            attributes {
              key
              value
            }
            externalLink
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      ...filter,
    }
  );

export const locVsPRCycleTimeDetailsGraphql = async (
  startDate: string,
  endDate: string,
  filter: {
    teamId?: string;
    sprintId?: string;
    lowerBoundIncl?: number;
    upperBound?: number;
    type?: string;
    status?: string;
    prSize?: string;
    orderBy?: string;
    orderColumn?: string;
    filterAge?: string;
    reviewer?: string;
    author?: string;
    projectsFilter?: string[];
    name?: string;
    prReviewResponsivenessType?: string;
    prFeedbackResponsivenessType?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> => {
  return filter?.name === 'Review Responsiveness Index'
    ? await graphQLClient.request(
        gql`
          query GetLocVsPRCycleTimeDetails(
            $orgId: String
            $startDate: String!
            $endDate: String
            $teamId: String
            $sprintId: String
            $lowerBoundIncl: Int!
            $upperBound: Int
            $pageNumber: Int!
            $pageSize: Int!
            $type: String!
            $status: String
            $prSize: String
            $orderBy: String
            $orderColumn: String
            $filterAge: String
            $reviewer: String
            $author: String
            $projectsFilter: [String]
            $prReviewResponsivenessType: String
          ) {
            LocVsPRCycleTimeDetails(
              filter: {
                startDate: $startDate
                endDate: $endDate
                orgId: $orgId
                teamId: $teamId
                sprintId: $sprintId
                type: $type
                status: $status
                reviewer: $reviewer
                author: $author
                projectsFilter: $projectsFilter
                prViewFilters: {
                  prReviewResponsivenessType: $prReviewResponsivenessType
                  prSize: $prSize
                  age: $filterAge
                }
              }
              orderBy: $orderBy
              orderColumn: $orderColumn
              lowerBoundIncl: $lowerBoundIncl
              upperBound: $upperBound
              pageNumber: $pageNumber
              pageSize: $pageSize
            ) {
              data {
                prDetail {
                  id
                  externalUrl
                  repo
                  title
                  issueId
                  addition
                  deletion
                  branch
                  batchSize
                }
                teams
                author {
                  avatar
                  name
                  commitCount
                }
                createdAt
                mergedAt
                reviewersDetails {
                  contributors {
                    avatar
                    name
                    commitCount
                  }
                  approvedBy {
                    avatar
                    name
                    commitCount
                  }
                  comments
                }
                prCycleTime {
                  color
                  time
                }
                breakdowns {
                  title
                  icon
                  value
                }
              }
              totalPages
              currentPage
              totalCount
            }
          }
        `,
        {
          orgId: `${localStorage.getItem('orgId')}`,
          startDate,
          endDate,
          pageNumber,
          pageSize,
          ...filter,
        }
      )
    : filter?.name === 'Feedback Responsiveness Index'
    ? await graphQLClient.request(
        gql`
          query GetLocVsPRCycleTimeDetails(
            $orgId: String
            $startDate: String!
            $endDate: String
            $teamId: String
            $sprintId: String
            $lowerBoundIncl: Int!
            $upperBound: Int
            $pageNumber: Int!
            $pageSize: Int!
            $type: String!
            $status: String
            $prSize: String
            $orderBy: String
            $orderColumn: String
            $filterAge: String
            $reviewer: String
            $author: String
            $projectsFilter: [String]
            $prFeedbackResponsivenessType: String
          ) {
            LocVsPRCycleTimeDetails(
              filter: {
                startDate: $startDate
                endDate: $endDate
                orgId: $orgId
                teamId: $teamId
                sprintId: $sprintId
                type: $type
                status: $status
                reviewer: $reviewer
                author: $author
                projectsFilter: $projectsFilter
                prViewFilters: {
                  prFeedbackResponsivenessType: $prFeedbackResponsivenessType
                  prSize: $prSize
                  age: $filterAge
                }
              }
              orderBy: $orderBy
              orderColumn: $orderColumn
              lowerBoundIncl: $lowerBoundIncl
              upperBound: $upperBound
              pageNumber: $pageNumber
              pageSize: $pageSize
            ) {
              data {
                prDetail {
                  id
                  externalUrl
                  repo
                  title
                  issueId
                  addition
                  deletion
                  branch
                  batchSize
                }
                teams
                author {
                  avatar
                  name
                  commitCount
                }
                createdAt
                mergedAt
                reviewersDetails {
                  contributors {
                    avatar
                    name
                    commitCount
                  }
                  approvedBy {
                    avatar
                    name
                    commitCount
                  }
                  comments
                }
                prCycleTime {
                  color
                  time
                }
                breakdowns {
                  title
                  icon
                  value
                }
              }
              totalPages
              currentPage
              totalCount
            }
          }
        `,
        {
          orgId: `${localStorage.getItem('orgId')}`,
          startDate,
          endDate,
          pageNumber,
          pageSize,
          ...filter,
        }
      )
    : await graphQLClient.request(
        gql`
          query GetLocVsPRCycleTimeDetails(
            $orgId: String
            $startDate: String!
            $endDate: String
            $teamId: String
            $sprintId: String
            $lowerBoundIncl: Int!
            $upperBound: Int
            $pageNumber: Int!
            $pageSize: Int!
            $type: String!
            $status: String
            $prSize: String
            $orderBy: String
            $orderColumn: String
            $filterAge: String
            $reviewer: String
            $author: String
            $projectsFilter: [String]
          ) {
            LocVsPRCycleTimeDetails(
              filter: {
                startDate: $startDate
                endDate: $endDate
                orgId: $orgId
                teamId: $teamId
                sprintId: $sprintId
                type: $type
                status: $status
                reviewer: $reviewer
                author: $author
                projectsFilter: $projectsFilter
                prViewFilters: { prSize: $prSize, age: $filterAge }
              }
              orderBy: $orderBy
              orderColumn: $orderColumn
              lowerBoundIncl: $lowerBoundIncl
              upperBound: $upperBound
              pageNumber: $pageNumber
              pageSize: $pageSize
            ) {
              data {
                prDetail {
                  id
                  externalUrl
                  repo
                  title
                  issueId
                  addition
                  deletion
                  branch
                  batchSize
                }
                teams
                author {
                  avatar
                  name
                  commitCount
                }
                createdAt
                mergedAt
                reviewersDetails {
                  contributors {
                    avatar
                    name
                    commitCount
                  }
                  approvedBy {
                    avatar
                    name
                    commitCount
                  }
                  comments
                }
                prCycleTime {
                  color
                  time
                }
                breakdowns {
                  title
                  icon
                  value
                }
              }
              totalPages
              currentPage
              totalCount
            }
          }
        `,
        {
          orgId: `${localStorage.getItem('orgId')}`,
          startDate,
          endDate,
          pageNumber,
          pageSize,
          ...filter,
        }
      );
};

export const averageActiveDaysDetailGraphql = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    teamId?: string;
    author?: string;
    name?: string;
    projectsFilter?: string[];
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetAverageActiveDaysDetail(
        $orgId: String
        $startDate: String
        $endDate: String
        $teamId: String
        $author: String
        $sprintId: String
        $projectsFilter: [String]
      ) {
        AverageActiveDaysDetail(
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            projectsFilter: $projectsFilter
          }
        ) {
          email
          activeDays
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      ...filter,
    }
  );

export const commitReportDetailWithBreakdownGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  filter: {
    teamId?: string;
    author?: string;
    type?: string;
    projectsFilter?: string[];
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetCommitReportDetailWithBreakdown(
        $orgId: String
        $startDate: String!
        $endDate: String
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
        $projectsFilter: [String]
        $metric: String!
        $type: String
      ) {
        CommitReportDetailWithBreakdown(
          metric: $metric
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            projectsFilter: $projectsFilter
            type: $type
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            commitId
            link
            summary
            author
            repository
            createdAt
            title
            subTitle
            date
            externalLink
            attributes {
              key
              value
            }
            rework
            helped
            refactor
            newwork
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      ...filter,
      pageNumber,
      pageSize,
      metric,
    }
  );

export const meetingDetailsGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  filter: {
    teamId?: string;
    author?: string;
    type?: string;
    projectsFilter?: string[];
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetMeetingDetails(
        $orgId: String
        $startDate: String!
        $endDate: String
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
        $metric: String!
        $type: String
        $reviewer: String
        $status: String
        $label: String
        $branches: String
        $searchTerm: String
        $authors: [String]
        $sprintIds: [String]
      ) {
        MeetingDetails(
          metric: $metric
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            sprintIds: $sprintIds
            authors: $authors
            project: ""
            type: $type
            reviewer: $reviewer
            status: $status
            label: $label
            branches: $branches
            searchTerm: $searchTerm
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            title
            id
            startTime
            endTime
            duration
            organizer
            attendees
            calendarOwner
          }
          totalPages
          currentPage
          totalCount
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      ...filter,
      pageNumber,
      pageSize,
      metric,
      reviewer: '',
      status: '',
      label: '',
      branches: '',
      searchTerm: '',
      authors: [],
      sprintIds: [],
    }
  );
