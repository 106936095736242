import { FC, Fragment } from 'react';
import {
  Avatar,
  Box,
  Divider,
  Flex,
  IconButton,
  Skeleton,
  Stack,
  Text,
  Button,
  Tooltip,
  useTheme,
} from '@chakra-ui/react';
import { SlideBar } from '../../../appSlideBar';
import { Paginate } from '../../../appPaginate';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  AuthorIdFilter,
  CFR_CHART_KEYS,
  COMMIT_CHART_KEYS,
  DEPLOY_CHART_KEYS,
  GOAL_METRIC_GIT_KEYS,
  GOAL_METRIC_JIRA_KEYS,
  ISSUE_CHART_KEYS,
  PR_AGEING_CHARTS,
  PR_CHART_KEYS,
  PR_HEALTH_INDEX_CHARTS,
  REVIEW_RESPONSIVENESS_INDEX_CHARTS,
  TeamFilter,
  WORK_BREAKDOWN_CHARTS,
  LOC_VS_PR_CYCLE_TIME_CHARTS,
  ISSUE_LIFECYCLE_DIST_CHARTS,
  splitTime,
} from '../../../shared/utils';
import IgnoreIcon from '../../../icons/IgnoreIcon';
import { useIgnorePrOrIssue } from '../api';
import { useQueryClient } from '@tanstack/react-query';
import { useToastHook } from '../../../appToast';
import ProgressMultiColor from '../../../progressMultiColor';
import Badge from '../Badge';

import { useNavigate } from 'react-router-dom';
import { useDashboardStore } from '../../../hooks/useDashboardStore';

interface DefaultSideBarProps {
  openDrawer: boolean;
  handleDrawerClose: () => void;
  defaultData: any;
  heading: string;
  subHeading: string;
  isFetching: boolean;
  handlePageClick: any;
  currentChartMeta: any;
  querykey?: string;
  startDate?: string;
  endDate?: string;
  filterBy: AuthorIdFilter | TeamFilter;
  payload: any;
}

interface NavigationState {
  heading: string;
  filterBy: AuthorIdFilter | TeamFilter;
  currentChartMeta: any;
  payload: any;
}

const DefaultSideBar: FC<DefaultSideBarProps> = ({
  openDrawer,
  handleDrawerClose,
  defaultData,
  heading,
  subHeading,
  isFetching,
  handlePageClick,
  currentChartMeta,
  querykey,
  startDate,
  endDate,
  filterBy,
  payload,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [newToast] = useToastHook();
  const colors = useTheme();
  const { mutate: ignorePrOrIssue, isLoading: ignorePrOrIssueLoading } =
    useIgnorePrOrIssue();
  const { selected, selectedTeam, setSelected, setSelectedTeam, setActive } =
    useDashboardStore();

  const handlePrIgnoreOrIssue = (id: string) => {
    ignorePrOrIssue(
      {
        id,
        type: [
          ...PR_CHART_KEYS,
          ...GOAL_METRIC_GIT_KEYS,
          ...COMMIT_CHART_KEYS,
          ...WORK_BREAKDOWN_CHARTS,
        ].includes(currentChartMeta?.chartKey)
          ? 'ignoreGit'
          : [...ISSUE_CHART_KEYS, ...GOAL_METRIC_JIRA_KEYS].includes(
              currentChartMeta?.chartKey
            )
          ? 'ignoreIssue'
          : '',
      },
      {
        onSuccess: () => {
          newToast({
            message: 'The entry you ignored has been successfully processed.',
            status: 'success',
          });
          queryClient.invalidateQueries([querykey]);
          queryClient.invalidateQueries([currentChartMeta.chartKey]);
        },
        onError: () => {
          newToast({
            message:
              'There was an error processing your request. Please try again.',
            status: 'error',
          });
        },
      }
    );
  };

  return (
    <SlideBar
      width={'460px'}
      openDrawer={openDrawer}
      currentChartMeta={currentChartMeta}
      startDate={startDate}
      endDate={endDate}
      filterBy={filterBy}
      handleDrawerClose={handleDrawerClose}
      sideBarHeading={heading}
      sideBarSubHeading={
        <>
          <Text
            fontFamily="heading"
            fontSize="sm"
            color={'text.secondary'}
            fontWeight={'medium'}
          >
            {subHeading}
          </Text>

          {defaultData &&
            defaultData.data &&
            defaultData?.data[0]?.readings && (
              <Flex>
                {defaultData?.data[0]?.readings?.map((item: any) => (
                  <Badge
                    dotColor={item.color}
                    text={
                      item.name &&
                      item.name.charAt(0).toUpperCase() + item.name.slice(1)
                    }
                  />
                ))}
              </Flex>
            )}

          {[
            ...PR_CHART_KEYS,
            ...PR_AGEING_CHARTS,
            ...PR_HEALTH_INDEX_CHARTS,
            ...LOC_VS_PR_CYCLE_TIME_CHARTS,
            ...REVIEW_RESPONSIVENESS_INDEX_CHARTS,
          ].includes(currentChartMeta.chartKey) &&
            !isFetching && (
              <Button
                onClick={() => {
                  const state: NavigationState = {
                    heading,
                    currentChartMeta: JSON.parse(
                      JSON.stringify(currentChartMeta)
                    ),
                    filterBy: JSON.parse(JSON.stringify(filterBy)),
                    payload: JSON.parse(JSON.stringify(payload)),
                  };

                  setSelected({
                    id: 6,
                    startDate: startDate as string,
                    endDate: endDate as string,
                    duration: 'Custom',
                    sprintId: '',
                  });
                  setActive('Custom');
                  // setTimeout(() => {
                  navigate('/pr-details', { state });
                  // }, 100);
                }}
                variant={'primary'}
                maxW={'fit-content'}
                mt={4}
              >
                More Details
              </Button>
            )}
        </>
      }
      content={
        <Stack spacing={1}>
          {isFetching &&
            Array.from(Array(8).keys()).map((_: any, index: number) => (
              <Skeleton key={index} height={'100px'} width={'100%'} />
            ))}

          {defaultData?.data?.map((item: any, idx: number, arr: any) => (
            <Fragment key={item?.id}>
              <Box p={2}>
                <Stack spacing={1}>
                  <Flex justifyContent={'space-between'}>
                    <Box>
                      {item?.updatedAt ? (
                        <Text
                          fontFamily="heading"
                          fontSize="xs"
                          color="text.secondary"
                          fontWeight="semibold"
                        >
                          {moment(item?.updatedAt).format('lll')}
                        </Text>
                      ) : (
                        item?.date && (
                          <Text
                            fontFamily="heading"
                            fontSize="xs"
                            color="text.secondary"
                            fontWeight="semibold"
                          >
                            {moment(item?.date).format('lll')}
                          </Text>
                        )
                      )}
                      {/meeting/i.test(heading) ? (
                        <Text
                          fontSize="md"
                          fontFamily="heading"
                          color="gray.700"
                          fontWeight="semibold"
                        >
                          {item?.title}
                        </Text>
                      ) : (
                        <Link
                          to={`/timeline/${
                            [...CFR_CHART_KEYS].includes(
                              currentChartMeta?.chartKey
                            )
                              ? 'cfr'
                              : [
                                  ...PR_CHART_KEYS,
                                  ...GOAL_METRIC_GIT_KEYS,
                                  ...COMMIT_CHART_KEYS,
                                  ...PR_AGEING_CHARTS,
                                  ...PR_HEALTH_INDEX_CHARTS,
                                  ...REVIEW_RESPONSIVENESS_INDEX_CHARTS,
                                  ...WORK_BREAKDOWN_CHARTS,
                                  ...LOC_VS_PR_CYCLE_TIME_CHARTS,
                                ].includes(currentChartMeta?.chartKey)
                              ? 'pr'
                              : [
                                  ...ISSUE_CHART_KEYS,
                                  ...GOAL_METRIC_JIRA_KEYS,
                                  ...ISSUE_LIFECYCLE_DIST_CHARTS,
                                ].includes(currentChartMeta?.chartKey)
                              ? 'issue'
                              : [...DEPLOY_CHART_KEYS].includes(
                                  currentChartMeta?.chartKey
                                )
                              ? 'build'
                              : 'issue'
                          }/${item?.id || item.commitId}`}
                        >
                          <Text
                            fontSize="md"
                            fontFamily="heading"
                            textDecoration="underline"
                            color="gray.700"
                            fontWeight="medium"
                          >
                            {item?.title}
                          </Text>
                        </Link>
                      )}

                      {item?.author && (
                        <Flex align="center">
                          <Avatar
                            size="xs"
                            bg="text.secondary"
                            opacity={0.8}
                            name={item?.author?.toUpperCase()?.charAt(0)}
                          />
                          <Text
                            fontSize="sm"
                            fontFamily="heading"
                            color="text.secondary"
                            fontWeight="medium"
                            ml={1}
                          >
                            {item?.author}
                          </Text>
                        </Flex>
                      )}
                    </Box>

                    {[
                      ...PR_CHART_KEYS,
                      ...GOAL_METRIC_GIT_KEYS,
                      ...COMMIT_CHART_KEYS,
                      ...ISSUE_CHART_KEYS,
                      ...GOAL_METRIC_JIRA_KEYS,
                      ...WORK_BREAKDOWN_CHARTS,
                    ].includes(currentChartMeta?.chartKey) && (
                      <Tooltip
                        hasArrow
                        placement="bottom-end"
                        label={`Clicking this icon will cease tracking for this entry. Are you sure?`}
                      >
                        <IconButton
                          icon={<IgnoreIcon color={'#718096'} />}
                          size={'sm'}
                          onClick={() =>
                            handlePrIgnoreOrIssue(item.id || item.commitId)
                          }
                          variant={'solid'}
                          colorScheme={colors.secondary}
                          isDisabled={ignorePrOrIssueLoading}
                          aria-label={'Ignore PR'}
                          mr={2}
                          bgColor={'white'}
                          p={2}
                          borderRadius={'lg'}
                        />
                      </Tooltip>
                    )}
                  </Flex>

                  <Flex gap={6}>
                    {item.mergeTime && (
                      <Flex align="center">
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="semibold"
                          color="gray.600"
                          whiteSpace={'nowrap'}
                          mr={1}
                        >
                          Merge Time
                        </Text>{' '}
                        <Text mt={'-3px'}>:</Text>
                        <Text
                          ml={'3px'}
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.secondary"
                        >
                          {item.mergeTime}
                        </Text>
                      </Flex>
                    )}
                    {item.pickupTime && (
                      <Flex align="center">
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="semibold"
                          color="gray.600"
                          whiteSpace={'nowrap'}
                          mr={1}
                        >
                          Pickup Time
                        </Text>{' '}
                        <Text mt={'-3px'}>:</Text>
                        <Text
                          ml={'3px'}
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.secondary"
                        >
                          {item.pickupTime}
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                  <Flex flexWrap="wrap" gap={2}>
                    {(item?.newwork || item?.newwork === 0) && (
                      <Flex align="center">
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="semibold"
                          color="gray.600"
                          whiteSpace={'nowrap'}
                          mr={1}
                        >
                          New Work Lines
                        </Text>{' '}
                        <Text mt={'-3px'}>:</Text>
                        <Text
                          ml={'3px'}
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.secondary"
                        >
                          {item?.newwork}
                        </Text>
                      </Flex>
                    )}

                    {(item?.refactor || item?.refactor === 0) && (
                      <Flex align="center">
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="semibold"
                          color="gray.600"
                          whiteSpace={'nowrap'}
                          mr={1}
                        >
                          Refactor Lines
                        </Text>{' '}
                        <Text mt={'-3px'}>:</Text>
                        <Text
                          ml={'3px'}
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.secondary"
                        >
                          {item?.refactor}
                        </Text>
                      </Flex>
                    )}

                    {(item?.rework || item?.rework === 0) && (
                      <Flex align="center">
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="semibold"
                          color="gray.600"
                          whiteSpace={'nowrap'}
                          mr={1}
                        >
                          Rework Lines
                        </Text>{' '}
                        <Text mt={'-3px'}>:</Text>
                        <Text
                          ml={'3px'}
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.secondary"
                        >
                          {item?.rework}
                        </Text>
                      </Flex>
                    )}

                    {(item?.helped || item.helped === 0) && (
                      <Flex align="center">
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="semibold"
                          color="gray.600"
                          whiteSpace={'nowrap'}
                          mr={1}
                        >
                          Helped Others Lines
                        </Text>{' '}
                        <Text mt={'-3px'}>:</Text>
                        <Text
                          ml={'3px'}
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.secondary"
                        >
                          {item?.helped}
                        </Text>
                      </Flex>
                    )}
                  </Flex>

                  {/* For meeting data */}
                  {/meeting/i.test(heading) && (
                    <Flex flexWrap="wrap" gap={2}>
                      {item?.startTime && (
                        <Flex align="center">
                          <Text
                            fontFamily="heading"
                            fontSize="sm"
                            fontWeight="semibold"
                            color="gray.600"
                            whiteSpace={'nowrap'}
                            mr={1}
                          >
                            Start Time
                          </Text>{' '}
                          <Text mt={'-3px'}>:</Text>
                          <Text
                            ml={'3px'}
                            fontFamily="heading"
                            fontSize="sm"
                            fontWeight="medium"
                            color="text.secondary"
                          >
                            {moment(item?.startTime).format('lll')}
                          </Text>
                        </Flex>
                      )}

                      {item?.endTime && (
                        <Flex align="center">
                          <Text
                            fontFamily="heading"
                            fontSize="sm"
                            fontWeight="semibold"
                            color="gray.600"
                            whiteSpace={'nowrap'}
                            mr={1}
                          >
                            End Time
                          </Text>{' '}
                          <Text mt={'-3px'}>:</Text>
                          <Text
                            ml={'3px'}
                            fontFamily="heading"
                            fontSize="sm"
                            fontWeight="medium"
                            color="text.secondary"
                          >
                            {moment(item?.endTime).format('lll')}
                          </Text>
                        </Flex>
                      )}

                      {item?.duration && (
                        <Flex align="center">
                          <Text
                            fontFamily="heading"
                            fontSize="sm"
                            fontWeight="semibold"
                            color="gray.600"
                            whiteSpace={'nowrap'}
                            mr={1}
                          >
                            Duration
                          </Text>{' '}
                          <Text mt={'-3px'}>:</Text>
                          <Text
                            ml={'3px'}
                            fontFamily="heading"
                            fontSize="sm"
                            fontWeight="medium"
                            color="text.secondary"
                          >
                            {splitTime(item?.duration / 60)}
                          </Text>
                        </Flex>
                      )}

                      {item?.duration && (
                        <Flex align="center">
                          <Text
                            fontFamily="heading"
                            fontSize="sm"
                            fontWeight="semibold"
                            color="gray.600"
                            whiteSpace={'nowrap'}
                            mr={1}
                          >
                            Duration
                          </Text>{' '}
                          <Text mt={'-3px'}>:</Text>
                          <Text
                            ml={'3px'}
                            fontFamily="heading"
                            fontSize="sm"
                            fontWeight="medium"
                            color="text.secondary"
                          >
                            {item?.duration}
                          </Text>
                        </Flex>
                      )}

                      {item?.organizer && (
                        <Flex align="center">
                          <Text
                            fontFamily="heading"
                            fontSize="sm"
                            fontWeight="semibold"
                            color="gray.600"
                            whiteSpace={'nowrap'}
                            mr={1}
                          >
                            Organizer
                          </Text>{' '}
                          <Text mt={'-3px'}>:</Text>
                          <Text
                            ml={'3px'}
                            fontFamily="heading"
                            fontSize="sm"
                            fontWeight="medium"
                            color="text.secondary"
                          >
                            {item?.organizer}
                          </Text>
                        </Flex>
                      )}

                      {item?.calendarOwner && (
                        <Flex align="center">
                          <Text
                            fontFamily="heading"
                            fontSize="sm"
                            fontWeight="semibold"
                            color="gray.600"
                            whiteSpace={'nowrap'}
                            mr={1}
                          >
                            Calendar Owner
                          </Text>{' '}
                          <Text mt={'-3px'}>:</Text>
                          <Text
                            ml={'3px'}
                            fontFamily="heading"
                            fontSize="sm"
                            fontWeight="medium"
                            color="text.secondary"
                          >
                            {item?.calendarOwner}
                          </Text>
                        </Flex>
                      )}

                      {item?.attendees && (
                        <Flex align="flex-start">
                          <Text
                            fontFamily="heading"
                            fontSize="sm"
                            fontWeight="semibold"
                            color="gray.600"
                            whiteSpace={'nowrap'}
                            mr={1}
                          >
                            Attendees
                          </Text>{' '}
                          <Text mt={'-3px'}>:</Text>
                          <Text
                            ml={'3px'}
                            fontFamily="heading"
                            fontSize="sm"
                            fontWeight="medium"
                            color="text.secondary"
                          >
                            {item?.attendees
                              .map((attendee: string) => attendee)
                              .join(', ')}
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  )}

                  <Flex direction={'column'}>
                  {item?.id && (
                      <Flex mr={3} align="flex-start">
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="semibold"
                          color="gray.600"
                          whiteSpace={'nowrap'}
                          mr={1}
                        >
                          ID
                        </Text>{' '}
                        <Text mt={'-3px'}>:</Text>
                        <Text
                          ml={1}
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.secondary"
                          wordBreak={'break-all'}
                        >
                          {item?.id}
                        </Text>
                      </Flex>
                    )}
                    {item?.pipelineName && (
                      <Flex mr={3} align="flex-start">
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="semibold"
                          color="gray.600"
                          whiteSpace={'nowrap'}
                          mr={1}
                        >
                          Pipeline Name
                        </Text>{' '}
                        <Text mt={'-3px'}>:</Text>
                        <Text
                          ml={1}
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.secondary"
                          wordBreak={'break-all'}
                        >
                          {item?.pipelineName}
                        </Text>
                      </Flex>
                    )}
                    {item?.repo && (
                      <Flex mr={3} align="flex-start">
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="semibold"
                          color="gray.600"
                          whiteSpace={'nowrap'}
                          mr={1}
                        >
                          Repositories
                        </Text>{' '}
                        <Text mt={'-3px'}>:</Text>
                        <Text
                          ml={1}
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.secondary"
                          wordBreak={'break-all'}
                        >
                          {item?.repo}
                        </Text>
                      </Flex>
                    )}
                    {item?.artifact && (
                      <Flex mr={3} align="flex-start">
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="semibold"
                          color="gray.600"
                          whiteSpace={'nowrap'}
                          mr={1}
                        >
                          Artifact
                        </Text>{' '}
                        <Text mt={'-3px'}>:</Text>
                        <Text
                          ml={1}
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.secondary"
                          wordBreak={'break-all'}
                        >
                          {item?.artifact}
                        </Text>
                      </Flex>
                    )}
                    {item?.buildTime && (
                      <Flex mr={3} align="flex-start">
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="semibold"
                          color="gray.600"
                          whiteSpace={'nowrap'}
                          mr={1}
                        >
                          Build Time
                        </Text>{' '}
                        <Text mt={'-3px'}>:</Text>
                        <Text
                          ml={1}
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.secondary"
                          wordBreak={'break-all'}
                        >
                          {item?.buildTime}
                        </Text>
                      </Flex>
                    )}
                    {item?.commit && (
                      <Flex mr={3} align="flex-start">
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="semibold"
                          color="gray.600"
                          whiteSpace={'nowrap'}
                          mr={1}
                        >
                          Commit ID
                        </Text>{' '}
                        <Text mt={'-3px'}>:</Text>
                        <Text
                          ml={1}
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.secondary"
                          wordBreak={'break-all'}
                        >
                          {item?.commit}
                        </Text>
                      </Flex>
                    )}
                    {item?.environment && (
                      <Flex mr={3} align="flex-start">
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="semibold"
                          color="gray.600"
                          whiteSpace={'nowrap'}
                          mr={1}
                        >
                          Environment
                        </Text>{' '}
                        <Text mt={'-3px'}>:</Text>
                        <Text
                          ml={1}
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.secondary"
                          wordBreak={'break-all'}
                        >
                          {item?.environment}
                        </Text>
                      </Flex>
                    )}
                    {item?.project && (
                      <Flex mr={3} align="flex-start">
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="semibold"
                          color="gray.600"
                          whiteSpace={'nowrap'}
                          mr={1}
                        >
                          Project
                        </Text>{' '}
                        <Text mt={'-3px'}>:</Text>
                        <Text
                          ml={1}
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.secondary"
                          wordBreak={'break-all'}
                        >
                          {item?.project}
                        </Text>
                      </Flex>
                    )}
                    {item?.status && (
                      <Flex mr={3} align="flex-start">
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="semibold"
                          color="gray.600"
                          whiteSpace={'nowrap'}
                          mr={1}
                        >
                          Status
                        </Text>{' '}
                        <Text mt={'-3px'}>:</Text>
                        <Text
                          ml={1}
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.secondary"
                          wordBreak={'break-all'}
                        >
                          {item?.status}
                        </Text>
                      </Flex>
                    )}
                    {item?.type && (
                      <Flex mr={3} align="flex-start">
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="semibold"
                          color="gray.600"
                          whiteSpace={'nowrap'}
                          mr={1}
                        >
                          Type
                        </Text>{' '}
                        <Text mt={'-3px'}>:</Text>
                        <Text
                          ml={1}
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.secondary"
                          wordBreak={'break-all'}
                        >
                          {item?.type}
                        </Text>
                      </Flex>
                    )}
                  </Flex>

                  <Flex align="center" flexWrap="wrap">
                    {item?.attributes?.map((att: any, idx: number) => {
                      if (att?.key === 'Additions') {
                        return (
                          <Flex mr={3} key={`code-changes`} align="flex-start">
                            <Text
                              fontFamily="heading"
                              fontSize="sm"
                              fontWeight="semibold"
                              color="gray.600"
                              whiteSpace={'nowrap'}
                              mr={1}
                            >
                              Code Changes
                            </Text>{' '}
                            <Text mt={'-3px'}>:</Text>
                            <Flex ml={1} gap={1}>
                              <Tooltip
                                hasArrow
                                placement="bottom-start"
                                label={'Additions'}
                              >
                                <Text
                                  color="success"
                                  fontFamily="heading"
                                  fontSize="sm"
                                  fontWeight="medium"
                                >{`+${
                                  item?.attributes.find(
                                    (attr: any) => attr.key === 'Additions'
                                  )?.value === 'null'
                                    ? '0'
                                    : item?.attributes.find(
                                        (attr: any) => attr.key === 'Additions'
                                      )?.value
                                }`}</Text>
                              </Tooltip>
                              <Tooltip
                                hasArrow
                                placement="bottom-end"
                                label="Deletions"
                              >
                                <Text
                                  color="error"
                                  fontFamily="heading"
                                  fontSize="sm"
                                  fontWeight="medium"
                                >{`-${
                                  item?.attributes.find(
                                    (attr: any) => attr.key === 'Deletions'
                                  )?.value === 'null'
                                    ? '0'
                                    : item?.attributes.find(
                                        (attr: any) => attr.key === 'Deletions'
                                      )?.value
                                }`}</Text>
                              </Tooltip>
                            </Flex>
                          </Flex>
                        );
                      }

                      return (
                        att?.value &&
                        att?.key !== 'Deletions' && (
                          <Flex mr={3} key={`att-${idx}`} align="flex-start">
                            <Text
                              fontFamily="heading"
                              fontSize="sm"
                              fontWeight="semibold"
                              color="gray.600"
                              whiteSpace={'nowrap'}
                              mr={1}
                            >
                              {att?.key}
                            </Text>{' '}
                            <Text mt={'-3px'}>:</Text>
                            <Text
                              ml={1}
                              fontFamily="heading"
                              fontSize="sm"
                              fontWeight="medium"
                              color="text.secondary"
                              wordBreak={'break-all'}
                            >
                              {att?.key === 'Merged At' ||
                              att?.key === 'Created At' ||
                              att?.key === 'Updated At' ||
                              att?.key === 'Dated At'
                                ? moment(att?.value)?.format('lll')
                                : att?.key === 'Reviewers'
                                ? att?.value?.replace(/[{}]/g, '')
                                : att?.value}
                            </Text>
                          </Flex>
                        )
                      );
                    })}
                  </Flex>

                  <Flex>
                    {item.readings && item.readings?.length > 0 && (
                      <ProgressMultiColor unset readings={item.readings} />
                    )}
                  </Flex>
                </Stack>
              </Box>
              {idx !== arr.length - 1 && <Divider />}
            </Fragment>
          ))}

          <Box marginLeft="-20px" my={'16px'}>
            {defaultData?.data?.length > 1 && (
              <Paginate
                currentPage={defaultData?.currentPage + 1}
                totalPageCount={defaultData?.totalPages}
                pageSize={20}
                onPageChange={(page) => handlePageClick(page as number)}
              />
            )}
          </Box>
        </Stack>
      }
    />
  );
};

export default DefaultSideBar;
