import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required.'),
  dimensions: Yup.array()
    .of(
      Yup.object().shape({
        dimensionId: Yup.string().required('Dimension is required'),
        value: Yup.string().required('Value is required'),
        // condition: Yup.string().required('Condition is required'),
      })
    )
    .min(1, 'At least one dimension must be added'),
  measure: Yup.object()
    .shape({
      label: Yup.string().required('Measure is required'),
      value: Yup.string().required('Measure is required'),
    })
    .nullable()
    .required('Measure is required'),
  chartType: Yup.object()
    .shape({
      label: Yup.string().required('Chart Type is required'),
      value: Yup.string().required('Chart Type is required'),
    })
    .nullable()
    .required('Chart Type is required'),
  improvement: Yup.object()
    .shape({
      label: Yup.string().required('Improvement is required'),
      value: Yup.string().required('Improvement is required'),
    })
    .nullable()
    .required('Improvement is required'),
});
