import {
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';
import {
  averageActiveDaysDetailGraphql,
  changeFailureGraphql,
  commitGraphql,
  customMetricsGraphql,
  DeploymentDetails,
  getContributorGraphql,
  GoalMetricDetailsGit,
  GoalMetricDetailsJira,
  issueAgeingDetailsGraphql,
  issueChartsGraphql,
  memberCountGraphql,
  PrAgeingDetailsGraphql,
  prChartsGraphql,
  prHealthIndexGraphql,
  prReviewResponsivenessDetailsGraphql,
  QualityDetails,
  VulnerabilityAlertsDetails,
  requirementStabilityDetailsGraphql,
  WellbeingDetails,
  workingAgreementGraphql,
  locVsPrCycleTimeDetailsGraphql,
  commitReportDetailWithBreakdownGraphql,
  customBuildMetricGraphql,
  customIssueMetricGraphql,
  locVsPRCycleTimeDetailsGraphql,
  PRHealthIndexGraphql,
  PRAgeingDetailsGraphql,
  PRChartsGraphql,
  PRReviewResponsivenessDetailsGraphql,
  meetingDetailsGraphql,
} from './query';
import {
  CFR_CHART_KEYS,
  CONTRIBUTOR_CHART_KEYS,
  DEPLOY_CHART_KEYS,
  GOAL_METRIC_GIT_KEYS,
  GOAL_METRIC_JIRA_KEYS,
  ISSUE_CHART_KEYS,
  PR_CHART_KEYS,
  WELLBEING_CHART_KEYS,
  WORKING_AGREEMENT_KEYS,
  MEMBER_CHART_KEYS,
  COMMIT_CHART_KEYS,
  QUALITY_CHART_KEYS,
  ISSUE_AGE_CHART_KEYS,
  REQUIREMENT_STABILITY_CHARTS,
  PR_AGEING_CHARTS,
  REVIEW_RESPONSIVENESS_INDEX_CHARTS,
  PR_HEALTH_INDEX_CHARTS,
  ACTIVE_DAYS_CHARTS,
  DEPENDENCY_VULNERABILITIES_CHART_KEYS,
  LOC_VS_PR_CYCLE_TIME_CHARTS,
  WORK_BREAKDOWN_CHARTS,
  ISSUE_LIFECYCLE_DIST_CHARTS,
  MEETING_CHARTS,
} from '../../shared/utils';

export const allMetricsList: { type: string; query: string }[] = [
  ...PR_CHART_KEYS.map((key: string) => ({ type: key, query: 'prReport' })),

  ...GOAL_METRIC_GIT_KEYS.map((key: string) => ({
    type: key,
    query: 'goalMetricDetailsGit',
  })),

  ...GOAL_METRIC_JIRA_KEYS.map((key: string) => ({
    type: key,
    query: 'goalMetricDetailsJira',
  })),

  ...ISSUE_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'issueReport',
  })),

  ...CFR_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'changeFailure',
  })),

  ...DEPLOY_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'deploymentDetails',
  })),

  ...WELLBEING_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'wellbeing',
  })),

  ...CONTRIBUTOR_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'contributors',
  })),

  ...MEMBER_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'memberCount',
  })),

  ...COMMIT_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'commitReport',
  })),

  ...WORKING_AGREEMENT_KEYS.map((key: string) => ({
    type: key,
    query: 'workingAgreement',
  })),

  ...QUALITY_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'qualityDetail',
  })),

  ...DEPENDENCY_VULNERABILITIES_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'vulnerabilityAlertsDetail',
  })),

  ...ISSUE_AGE_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'issueAgeing',
  })),

  ...REQUIREMENT_STABILITY_CHARTS.map((key: string) => ({
    type: key,
    query: 'requirementStability',
  })),

  ...PR_AGEING_CHARTS.map((key: string) => ({
    type: key,
    query: 'prAgeingDistribution',
  })),

  ...REVIEW_RESPONSIVENESS_INDEX_CHARTS.map((key: string) => ({
    type: key,
    query: 'prReviewResponsivenessDetails',
  })),

  ...PR_HEALTH_INDEX_CHARTS.map((key: string) => ({
    type: key,
    query: 'prHealthIndexDetails',
  })),

  ...LOC_VS_PR_CYCLE_TIME_CHARTS.map((key: string) => ({
    type: key,
    query: 'locVsPrCycleTimeDetails',
  })),

  ...ACTIVE_DAYS_CHARTS.map((key: string) => ({
    type: key,
    query: 'activeDays',
  })),

  ...WORK_BREAKDOWN_CHARTS.map((key: string) => ({
    type: key,
    query: 'workBreakdown',
  })),

  ...ISSUE_LIFECYCLE_DIST_CHARTS.map((key: string) => ({
    type: key,
    query: 'issueReport',
  })),

  ...MEETING_CHARTS.map((key: string) => ({
    type: key,
    query: 'meetingReport',
  })),
];

type DynamicQueryKey = string;

enum MetricsType {
  PrReport = 'prReport',
  IssueReport = 'issueReport',
  DeploymentDetails = 'deploymentDetails',
  ChangeFailure = 'changeFailure',
  GoalMetricDetailsGit = 'goalMetricDetailsGit',
  GoalMetricDetailsJira = 'goalMetricDetailsJira',
  Wellbeing = 'wellbeing',
  Contributors = 'contributors',
  CommitReport = 'commitReport',
  WorkingAgreement = 'workingAgreement',
  MemberCount = 'memberCount',
  QualityDetail = 'qualityDetail',
  VulnerabilityAlertsDetail = 'vulnerabilityAlertsDetail',
  IssueAgeing = 'issueAgeing',
  RequirementStability = 'requirementStability',
  PrAgeingDistribution = 'prAgeingDistribution',
  PrReviewResponsivenessDetails = 'prReviewResponsivenessDetails',
  PrHealthIndexDetails = 'prHealthIndexDetails',
  LocVsPrCycleTimeDetails = 'locVsPrCycleTimeDetails',
  ActiveDays = 'activeDays',
  WorkBreakdown = 'workBreakdown',
  MeetingReport = 'meetingReport',
}

export const useChartTableGql = (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: { [key: string]: string | string[] },
  pageNumber: number,
  pageSize: number,
  detailEntity: string,
  metricTable = false
) => {
  const query = allMetricsList
    .filter((item: any) => item.type === metricType)
    .map((el: any) => el.query)[0];

  let queryFn: QueryFunction<any, QueryKey, number> = ({ pageParam = 0 }) =>
    metricTable
      ? PRChartsGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageParam,
          pageSize
        )
      : prChartsGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageParam,
          pageSize
        );

  switch (query) {
    case MetricsType.PrReport:
      if (metricTable) {
        queryFn = ({ pageParam = 0 }) =>
          PRChartsGraphql(
            startDate,
            endDate,
            metricType,
            filter,
            pageParam,
            pageSize
          );
      } else {
        queryFn = () =>
          prChartsGraphql(
            startDate,
            endDate,
            metricType,
            filter,
            pageNumber,
            pageSize
          );
      }

      break;

    case MetricsType.IssueReport:
      queryFn = () =>
        issueChartsGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.ChangeFailure:
      queryFn = () =>
        changeFailureGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.DeploymentDetails:
      queryFn = () =>
        DeploymentDetails(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;
    case MetricsType.Wellbeing:
      queryFn = () =>
        WellbeingDetails(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.QualityDetail:
      queryFn = () =>
        QualityDetails(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.VulnerabilityAlertsDetail:
      queryFn = () =>
        VulnerabilityAlertsDetails(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.GoalMetricDetailsGit:
      queryFn = () =>
        GoalMetricDetailsGit(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;
    case MetricsType.GoalMetricDetailsJira:
      queryFn = () =>
        GoalMetricDetailsJira(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;
    case MetricsType.Contributors:
      queryFn = () => getContributorGraphql(startDate, endDate, filter);
      break;

    case MetricsType.WorkingAgreement:
      queryFn = () => workingAgreementGraphql(startDate, endDate, filter);
      break;

    case MetricsType.MemberCount:
      queryFn = () => memberCountGraphql(filter);
      break;

    case MetricsType.CommitReport:
      queryFn = () =>
        commitGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.IssueAgeing:
      queryFn = () =>
        issueAgeingDetailsGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.RequirementStability:
      queryFn = () =>
        requirementStabilityDetailsGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.PrAgeingDistribution:
      if (metricTable) {
        queryFn = ({ pageParam = 0 }) =>
          PRAgeingDetailsGraphql(
            startDate,
            endDate,
            filter,
            pageParam,
            pageSize
          );
      } else {
        queryFn = ({ pageParam = 0 }) =>
          PrAgeingDetailsGraphql(startDate, endDate, filter);
      }
      break;

    case MetricsType.PrReviewResponsivenessDetails:
      if (metricTable) {
        queryFn = ({ pageParam = 0 }) =>
          PRReviewResponsivenessDetailsGraphql(
            startDate,
            endDate,
            metricType,
            filter,
            pageParam,
            pageSize
          );
      } else {
        queryFn = ({ pageParam = 0 }) =>
          prReviewResponsivenessDetailsGraphql(
            startDate,
            endDate,
            metricType,
            filter,
            pageNumber,
            pageSize
          );
      }
      break;
    case MetricsType.PrHealthIndexDetails:
      if (metricTable) {
        queryFn = ({ pageParam = 0 }) =>
          PRHealthIndexGraphql(
            startDate,
            endDate,
            metricType,
            filter,
            pageParam,
            pageSize
          );
      } else {
        queryFn = ({ pageParam = 0 }) =>
          prHealthIndexGraphql(
            startDate,
            endDate,
            metricType,
            filter,
            pageNumber,
            pageSize
          );
      }
      break;

    case MetricsType.LocVsPrCycleTimeDetails:
      if (metricTable) {
        queryFn = ({ pageParam = 0 }) =>
          locVsPRCycleTimeDetailsGraphql(
            startDate,
            endDate,
            filter,
            pageParam,
            pageSize
          );
      } else {
        queryFn = ({ pageParam = 0 }) =>
          locVsPrCycleTimeDetailsGraphql(startDate, endDate, filter);
      }
      break;

    case MetricsType.ActiveDays:
      queryFn = () =>
        averageActiveDaysDetailGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.WorkBreakdown:
      queryFn = () =>
        commitReportDetailWithBreakdownGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.MeetingReport:
      queryFn = () =>
        meetingDetailsGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    default:
      switch (detailEntity) {
        case 'BuildDetailCustom': {
          queryFn = () =>
            customBuildMetricGraphql(
              startDate,
              endDate,
              metricType,
              filter,
              pageNumber,
              pageSize
            );
          break;
        }
        case 'IssueDetailCustom': {
          queryFn = () =>
            customIssueMetricGraphql(
              startDate,
              endDate,
              metricType,
              filter,
              pageNumber,
              pageSize
            );
          break;
        }
        default: {
          queryFn = () =>
            customMetricsGraphql(
              startDate,
              endDate,
              metricType,
              filter,
              pageNumber,
              pageSize
            );
          break;
        }
      }
      break;
  }

  return useInfiniteQuery(
    [
      metricType as DynamicQueryKey,
      startDate,
      endDate,
      filter,
      pageNumber,
      pageSize,
      'metric_details',
    ],
    queryFn,
    {
      select: (data) => getPayload(query, data, metricTable),
      enabled: !!metricType,
      keepPreviousData: true,
      getNextPageParam: (_lastGroup, groups) => groups.length,
      refetchOnWindowFocus: false,
    }
  );
};

const getPayload = (query: string, graphQLData: any, metricTable: boolean) => {
  switch (query) {
    case MetricsType.PrReport: {
      if (metricTable) {
        const flatData =
          graphQLData?.pages?.flatMap(
            (page: any) => page.PRReportDetail.data
          ) ?? [];
        return {
          data: flatData,
          totalDBRowCount:
            graphQLData?.pages?.[graphQLData?.pages?.length - 1].PRReportDetail
              .totalCount,
        };
      } else {
        return {
          ...graphQLData?.pages?.[0].PrReportDetail,
        };
      }
    }

    case MetricsType.IssueReport:
      return {
        ...graphQLData?.pages?.[0].IssueReportDetail,
      };
    case MetricsType.ChangeFailure:
      return {
        ...graphQLData?.pages?.[0].ChangeFailureDetail,
      };
    case MetricsType.DeploymentDetails:
      return {
        ...graphQLData?.pages?.[0].DeploymentDetails,
      };
    case MetricsType.Wellbeing:
      return {
        ...graphQLData?.pages?.[0].WellBeingDetailsByType,
      };
    case MetricsType.GoalMetricDetailsGit:
      return {
        ...graphQLData?.pages?.[0].GoalMetricDetailsGit,
      };
    case MetricsType.GoalMetricDetailsJira:
      return {
        ...graphQLData?.pages?.[0].GoalMetricDetailsJira,
      };
    case MetricsType.Contributors:
      return {
        ...graphQLData?.pages?.[0].ContributorDetails,
      };
    case MetricsType.MemberCount:
      return {
        ...graphQLData?.pages?.[0].TeamMembers,
      };
    case MetricsType.WorkingAgreement:
      return {
        ...graphQLData?.pages?.[0].TeamStatsWorkingAgreementDetails,
      };
    case MetricsType.QualityDetail:
      return {
        ...graphQLData?.pages?.[0].QualityBranchDetailByType,
      };

    case MetricsType.VulnerabilityAlertsDetail:
      return {
        ...graphQLData?.pages?.[0].VulnerabilityAlertsDetail,
      };
    case MetricsType.CommitReport:
      return {
        ...graphQLData?.pages?.[0].CommitReportDetail,
        data: graphQLData?.pages?.[0].CommitReportDetail.data.map(
          (item: any) => ({
            ...item,
            id: item.attributes.find(
              (attr: any) => attr?.key === 'Related PR Id'
            )?.value,
          })
        ),
      };
    case MetricsType.IssueAgeing:
      return {
        ...graphQLData?.pages?.[0].IssueDetailsForAge,
      };
    case MetricsType.RequirementStability:
      return {
        ...graphQLData?.pages?.[0].RequirementStabilityDetails,
      };

    case MetricsType.PrAgeingDistribution: {
      if (metricTable) {
        const flatData =
          graphQLData?.pages?.flatMap(
            (page: any) => page.PRAgeingDetails.data
          ) ?? [];
        return {
          data: flatData,
          totalDBRowCount:
            graphQLData?.pages?.[graphQLData?.pages?.length - 1].PRAgeingDetails
              .totalCount,
        };
      } else {
        return {
          ...graphQLData?.pages?.[0].PrAgeingDetails,
        };
      }
    }

    case MetricsType.PrReviewResponsivenessDetails: {
      if (metricTable) {
        const flatData =
          graphQLData?.pages?.flatMap(
            (page: any) => page.PRReviewResponsivenessDetails.data
          ) ?? [];
        return {
          data: flatData,
          totalDBRowCount:
            graphQLData?.pages?.[graphQLData?.pages?.length - 1]
              .PRReviewResponsivenessDetails.totalCount,
        };
      } else {
        return {
          ...graphQLData?.pages?.[0].PrReviewResponsivenessDetails,
        };
      }
    }

    case MetricsType.PrHealthIndexDetails: {
      if (metricTable) {
        const flatData =
          graphQLData?.pages?.flatMap(
            (page: any) => page.PRHealthIndexesDetails.data
          ) ?? [];
        return {
          data: flatData,
          totalDBRowCount:
            graphQLData?.pages?.[graphQLData?.pages?.length - 1]
              .PRHealthIndexesDetails.totalCount,
        };
      } else {
        return {
          ...graphQLData?.pages?.[0].PrHealthIndexesDetails,
        };
      }
    }

    case MetricsType.LocVsPrCycleTimeDetails: {
      if (metricTable) {
        const flatData =
          graphQLData?.pages?.flatMap(
            (page: any) => page.LocVsPRCycleTimeDetails.data
          ) ?? [];
        return {
          data: flatData,
          totalDBRowCount:
            graphQLData?.pages?.[graphQLData?.pages?.length - 1]
              .LocVsPRCycleTimeDetails.totalCount,
        };
      } else {
        return {
          ...graphQLData?.pages?.[0].LocVsPrCycleTimeDetails,
        };
      }
    }

    case MetricsType.ActiveDays:
      return (
        graphQLData &&
        graphQLData?.pages?.[0].AverageActiveDaysDetail && [
          ...graphQLData.pages[0].AverageActiveDaysDetail,
        ]
      );
    case MetricsType.MeetingReport:
      return {
        ...graphQLData?.pages?.[0].MeetingDetails,
      };

    case MetricsType.WorkBreakdown:
      return {
        ...graphQLData?.pages?.[0].CommitReportDetailWithBreakdown,
        data: graphQLData?.pages?.[0].CommitReportDetailWithBreakdown?.data?.map(
          (item: any) => {
            const total =
              item.helped + item.newwork + item.refactor + item.rework;

            return {
              ...item,
              readings: [
                {
                  name: 'helped',
                  value: parseFloat(((item.helped / total) * 100)?.toFixed(4)),
                  color: '#F1B813',
                },
                {
                  name: 'newwork',
                  value: parseFloat(((item.newwork / total) * 100)?.toFixed(4)),
                  color: '#71E03A',
                },
                {
                  name: 'refactor',
                  value: parseFloat(
                    ((item.refactor / total) * 100)?.toFixed(4)
                  ),
                  color: '#0095E6',
                },
                {
                  name: 'rework',
                  value: parseFloat(((item.rework / total) * 100)?.toFixed(4)),
                  color: '#FF5230',
                },
              ],
            };
          }
        ),
      };

    default:
      return graphQLData?.pages?.[0].CustomMetricDetailJira
        ? {
            ...graphQLData?.pages?.[0].CustomMetricDetailJira,
          }
        : graphQLData?.pages?.[0].BuildDetailCustom
        ? {
            ...graphQLData?.pages?.[0].BuildDetailCustom,
          }
        : graphQLData?.pages?.[0].IssueDetailCustom
        ? {
            ...graphQLData?.pages?.[0].IssueDetailCustom,
          }
        : {};
  }
};
