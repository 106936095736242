import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Chart,
  Loader,
  PageHeader,
  splitTime,
  TextInput,
  useToastHook,
  ListSelect,
} from '@devd-client/devd/components';
import { Field, FieldArray, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Select } from 'chakra-react-select';
import {
  QUERY_KEYS,
  useDimension,
  useGetAllCustomDashboards,
  useGroupBy,
  useMeasure,
  useUpdateCustomDashboard,
  useCustomfield,
} from '../../../api';
import {
  useAddCustomMetricsMutation,
  useUpdateCustomMetricsMutation,
  useFetchCustomMetricsByName,
} from '@devd-client/devd/custom-metrics';
import { useCustomMetrics } from '../../../graphql';
import { useQueryClient } from '@tanstack/react-query';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MdOutlineClear } from 'react-icons/md';
import { FormValues } from '../AddMetricsSlidebar/types';
import { validationSchema } from '../AddMetricsSlidebar/validationSchema';
import {
  chartTypeOptions1,
  chartTypeOptions2,
} from '../AddMetricsSlidebar/utils';
import moment from 'moment';
import styled from 'styled-components';
import { useFetchAllGitProjects } from '@devd-client/devd/teams';

export const MetricLabelWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
`;
type CountProps = {
  color?: string;
};
export const Count = styled.h1<CountProps>`
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['4xl']};
  margin-right: 8px;
  line-height: 28px;
  color: ${({ color }) => color};
`;

export const Label = styled.h5`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: 12px;
  letter-spacing: 0.5px;
`;

const initialValues: FormValues = {
  name: '',
  dimensions: [],
  measure: null,
  groupBy: null,
  chartType: null,
  improvement: null,
};

const improvementOptions = [
  { value: 'DECREASED', label: 'Decreased' },
  { value: 'INCREASED', label: 'Increased' },
];

export const AddMetric: FC = () => {
  const listSelectModal = useDisclosure();
  const [newToast] = useToastHook();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const metric = searchParam.get('metric');

  const { data: gitProjects } = useFetchAllGitProjects();

  const { data: singleMetric, isLoading: singleMetricLoading } =
    useFetchCustomMetricsByName(metric!);

  const { mutate: saveCustomMetrics } = useAddCustomMetricsMutation();
  const { mutate: updateCustomMetrics } = useUpdateCustomMetricsMutation();
  const { data: allDashboard } = useGetAllCustomDashboards();
  const { mutate: handleUpdate } = useUpdateCustomDashboard();
  const [entity, setEntity] = useState('');
  const [showChartForGroup, setShowChartForGroup] = useState(false);
  const [showPreviewBox, setShowPreviewBox] = useState(false);
  const [savedMetric, setSavedMetric] = useState('');

  const [metricSaved, setMetricSaved] = useState(false);
  const [triggerSubmit, setTriggerSubmit] = useState(false);

  const [initialState, setInitialState] = useState(initialValues);
  const { data: customFieldData } = useCustomfield();
  const { data: measureData, isLoading: measureDataLoading } = useMeasure();
  const { data: dimensionData, isLoading: dimensionDataLoading } =
    useDimension(entity);
  const { data: groupByData, isLoading: groupByDataLoading } =
    useGroupBy(entity);

  const selected = {
    duration: '1M',
    startDate: moment().subtract(29, 'day').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    sprintId: '',
  };

  const { data, isFetching, refetch } = useCustomMetrics(
    selected.startDate,
    selected.endDate,
    savedMetric || metric!,
    '',
    '',
    '',
    'WEEK'
  );

  useEffect(() => {
    if (!metric && !singleMetricLoading) return;
    setShowPreviewBox(true);
    setEntity(
      measureData?.dto
        ?.filter(
          (measure: any) =>
            measure.metricId === Number(singleMetric?.dto?.measure)
        )
        ?.map((item: any) => ({
          value: String(item.metricId),
          label: item.displayName,
          entity: item.entity,
        }))[0]?.entity
    );

    if (singleMetric?.dto?.group_by) {
      setShowChartForGroup(true);
    }

    setInitialState({
      name: singleMetric?.dto?.key?.name,
      dimensions: singleMetric?.dto?.dimensions.map((item: any) => ({
        ...item,
        dimensionId: String(item.dimensionId),
      })),
      groupBy: groupByData?.dto
        ?.filter(
          (groupBy: any) => groupBy.field === singleMetric?.dto?.group_by
        )
        ?.map((item: any) => ({
          value: item.field,
          label: item.displayName,
        }))[0],
      measure: measureData?.dto
        ?.filter(
          (measure: any) =>
            measure.metricId === Number(singleMetric?.dto.measure)
        )
        ?.map((item: any) => ({
          value: String(item.metricId),
          label: item.displayName,
        }))[0],

      chartType:
        chartTypeOptions1
          .concat(chartTypeOptions2)
          .find((item: any) => item.value === singleMetric?.dto.chartType) ||
        null,
      improvement: improvementOptions?.find(
        (item: any) => item.value === singleMetric?.dto?.improvement
      ),
    });
  }, [
    groupByData?.dto,
    measureData?.dto,
    singleMetric?.dto?.chartType,
    singleMetric?.dto?.dimensions,
    singleMetric?.dto?.group_by,
    singleMetric?.dto?.key?.name,
    singleMetric?.dto?.improvement,
    metric,
    singleMetric?.dto?.measure,
    singleMetricLoading,
  ]);

  const onSubmit = (values: any, { resetForm }: any) => {
    if (metricSaved || metric) {
      updateCustomMetrics(
        {
          dimensions: values.dimensions.map((item: any) => ({
            dimensionId: item.dimensionId,
            value: item.value,
            operator: item.condition,
            customField: item.customField,
          })),
          name: values.name,
          measure: values.measure.value,
          chartType: values.chartType.value,
          group_by: values?.groupBy?.value || null,
          improvement: values.improvement.value,
        },
        {
          onSuccess: (data) => {
            setSavedMetric(data?.dto?.name);
            newToast({
              message: 'Metrics updated successfully!',
              status: 'success',
            });
            refetch();
            Promise.all([
              queryClient.invalidateQueries([QUERY_KEYS.customDashboards]),
              queryClient.invalidateQueries([
                QUERY_KEYS.customDashboardsDetails,
              ]),
            ]);
            navigate(-1);
          },
          onError: (err: any) => {
            newToast({
              message: err.message || 'Something went wrong!',
              status: 'error',
            });
          },
        }
      );
    } else {
      saveCustomMetrics(
        {
          dimensions: values.dimensions.map((item: any) => ({
            dimensionId: item.dimensionId,
            value: item.value,
            operator: item.condition,
            customField: item.customField,
          })),
          name: values.name,
          measure: values.measure.value,
          chartType: values.chartType.value,
          group_by: values?.groupBy?.value || null,
          improvement: values.improvement.value,
        },
        {
          onSuccess: (data) => {
            setSavedMetric(data?.dto?.name);
            setShowPreviewBox(true);
            newToast({
              message: 'Metrics saved successfully!',
              status: 'success',
            });
            setMetricSaved(true);
            if (
              allDashboard?.dto?.some(
                (item: any) => item.name === searchParam.get('id')
              )
            ) {
              const prevData = allDashboard?.dto?.find(
                (item: any) => item.name === searchParam.get('id')
              );
              handleUpdate(
                {
                  ...prevData,
                  layout: {
                    ...prevData?.layout,
                    metrics: [
                      ...prevData.layout.metrics,
                      { metric: values.name },
                    ],
                  },
                },
                {
                  onSuccess: () => {
                    Promise.all([
                      queryClient.invalidateQueries([
                        QUERY_KEYS.customDashboards,
                      ]),
                      queryClient.invalidateQueries([
                        QUERY_KEYS.customDashboardsDetails,
                      ]),
                    ]);
                  },
                }
              );
            }
            navigate(-1);
          },
          onError: (err: any) => {
            newToast({
              message: err.message || 'Something went wrong!',
              status: 'error',
            });
          },
        }
      );
    }
  };

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        width={'100%'}
        minHeight={'100%'}
      >
        <Box mb={5}>
          <PageHeader
            headingText={
              metric ? `Update Custom Metric - ${metric}` : 'Add Custom Metric'
            }
          />
          <Button onClick={() => navigate(-1)} size="sm" variant="link">
            Back
          </Button>
        </Box>
        <Stack spacing={4}>
          <Formik
            initialValues={initialState}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {(props) => {
              const { handleSubmit, setFieldValue, errors, touched, values } =
                props;

              const getLengthOfItem = (index: number) => {
                const selectedValues =
                  values.dimensions[index].value?.split(',') || [];

                const matchedItems = gitProjects?.dto?.filter((item: any) =>
                  selectedValues.includes(item.name)
                );

                return matchedItems?.length || 0;
              };

              return (
                <form onSubmit={handleSubmit as any}>
                  <Flex direction="column" gap={4} bg="gray.100" padding={6}>
                    <Flex align="center" gap={4}>
                      <Text
                        fontFamily={'heading'}
                        fontSize="sm"
                        fontWeight="semibold"
                        color="text.secondary"
                        mb={1}
                        width="100px"
                      >
                        Metric Name
                      </Text>
                      <Box width="300px">
                        <TextInput
                          name="name"
                          placeholder="Enter Metric Name"
                          isDisabled={metric}
                        />
                      </Box>
                    </Flex>

                    <Box w="full" h="1px" bg="#e0e0e0" />

                    <Flex align="center" gap={4}>
                      <Text
                        fontFamily={'heading'}
                        fontSize="sm"
                        fontWeight="semibold"
                        color="text.secondary"
                        mb={1}
                        width="100px"
                      >
                        Measures
                      </Text>
                      <Box minW="300px">
                        <Field name="measure">
                          {({ field, form }: any) => (
                            <Select
                              {...field}
                              options={measureData?.dto?.map((item: any) => ({
                                label: item.displayName,
                                value: String(item.metricId),
                                entity: item.entity,
                              }))}
                              useBasicStyles
                              placeholder="Select measure"
                              onChange={(selected: any) => {
                                const defaultDimensions = measureData?.dto
                                  .filter((item: any) => {
                                    return (
                                      item.metricId ===
                                        parseInt(selected.value) &&
                                      item.mandatoryDimensions?.length > 0
                                    );
                                  })[0]
                                  ?.mandatoryDimensions.map(
                                    (dimension: any) => {
                                      return {
                                        dimensionId: `${dimension}`,
                                        value: '',
                                        condition: 'EQUAL',
                                      };
                                    }
                                  );

                                setEntity(selected.entity);
                                setFieldValue(
                                  'dimensions',
                                  defaultDimensions ? defaultDimensions : []
                                );
                                setFieldValue('groupBy', null);
                                setFieldValue('chartType', null);
                                setShowChartForGroup(false);
                                setFieldValue('measure', selected);
                              }}
                              value={values.measure}
                            />
                          )}
                        </Field>
                        {touched.measure &&
                        typeof errors.measure === 'string' ? (
                          <Text as="span" fontSize="sm" color="red.500">
                            {errors.measure}
                          </Text>
                        ) : touched.measure &&
                          typeof errors.measure === 'object' ? (
                          <Text as="span" fontSize="sm" color="red.500">
                            Measure is required
                          </Text>
                        ) : null}
                      </Box>
                    </Flex>

                    <Box w="full" h="1px" bg="#e0e0e0" />

                    <Flex align="flex-start" gap={4}>
                      <Text
                        fontFamily={'heading'}
                        fontSize="sm"
                        fontWeight="semibold"
                        color="text.secondary"
                        mb={1}
                        width="100px"
                        mt={2}
                      >
                        Dimensions
                      </Text>
                      <FieldArray name="dimensions">
                        {({ push, remove }) => (
                          <Box>
                            {values.dimensions?.map((dimension, index) => {
                              const selectedMeasure = measureData?.dto.find(
                                (item: any) =>
                                  item.metricId ===
                                  parseInt(values.measure?.value || '')
                              );
                              const isMandatory =
                                selectedMeasure?.mandatoryDimensions?.includes(
                                  parseInt(dimension.dimensionId!)
                                );
                              return (
                                <Flex key={index} align="center" gap={4} mb={4}>
                                  <Box minWidth="200px">
                                    <Text
                                      fontFamily={'heading'}
                                      fontSize="sm"
                                      fontWeight="semibold"
                                      color="text.secondary"
                                    >
                                      Attribute
                                    </Text>
                                    <Field
                                      name={`dimensions.${index}.dimensionId`}
                                    >
                                      {({ field }: any) => (
                                        <Select
                                          {...field}
                                          options={dimensionData?.dto
                                            ?.map((item: any) => ({
                                              value: String(item.metricId),
                                              label: item.displayName,
                                            }))
                                            .filter(
                                              (option: any) =>
                                                !values.dimensions
                                                  .map(
                                                    (dimension: any) =>
                                                      dimension.dimensionId
                                                  )
                                                  .includes(option.value)
                                            )}
                                          placeholder="Select dimension"
                                          onChange={(selected: any) => {
                                            setFieldValue(
                                              `dimensions.${index}.dimensionId`,
                                              selected.value
                                            );
                                            setFieldValue(
                                              `dimensions.${index}.label`,
                                              selected.label
                                            );
                                          }}
                                          useBasicStyles
                                          value={dimensionData?.dto
                                            ?.map((item: any) => ({
                                              value: item.metricId,
                                              label: item.displayName,
                                            }))
                                            .find(
                                              (option: any) =>
                                                option.value ==
                                                values.dimensions[index]
                                                  .dimensionId
                                            )}
                                          isDisabled={isMandatory}
                                        />
                                      )}
                                    </Field>

                                    {touched.dimensions?.[index] &&
                                    typeof errors.dimensions?.[index] ===
                                      'object' &&
                                    (errors.dimensions[index] as any)
                                      .dimensionId ? (
                                      <Text
                                        as="span"
                                        fontSize="sm"
                                        color="red.500"
                                      >
                                        {
                                          (errors.dimensions[index] as any)
                                            .dimensionId
                                        }
                                      </Text>
                                    ) : null}
                                  </Box>

                                  {dimension.dimensionId === '16' && (
                                    <Box minWidth="200px">
                                      <Text
                                        fontFamily={'heading'}
                                        fontSize="sm"
                                        fontWeight="semibold"
                                        color="text.secondary"
                                      >
                                        Select Custom Field Name
                                      </Text>
                                      <Field
                                        name={`dimensions.${index}.customField`}
                                      >
                                        {({ field }: any) => (
                                          <Select
                                            {...field}
                                            options={customFieldData?.dto.map(
                                              (item: any) => {
                                                return {
                                                  label: item.name,
                                                  value: item.value,
                                                };
                                              }
                                            )}
                                            placeholder="Select custom Field"
                                            onChange={(selected: any) => {
                                              setFieldValue(
                                                `dimensions.${index}.customField`,
                                                selected.value
                                              );
                                            }}
                                            value={customFieldData?.dto
                                              ?.map((item: any) => ({
                                                value: item.value,
                                                label: item.name,
                                              }))
                                              .find(
                                                (option: any) =>
                                                  option.value ==
                                                  values.dimensions[index]
                                                    .dimensionId
                                              )}
                                            useBasicStyles
                                          />
                                        )}
                                      </Field>
                                    </Box>
                                  )}

                                  {dimension.dimensionId === '20' ? (
                                    <>
                                      <Box minWidth="150px">
                                        <Text
                                          fontFamily={'heading'}
                                          fontSize="sm"
                                          fontWeight="semibold"
                                          color="text.secondary"
                                        >
                                          {getLengthOfItem(index)} Repositories
                                          Linked
                                        </Text>
                                        <Box>
                                          <Button
                                            variant="primary"
                                            size="md"
                                            onClick={listSelectModal.onOpen}
                                          >
                                            Select Repository
                                          </Button>
                                        </Box>
                                        {touched.dimensions?.[index] &&
                                        typeof errors.dimensions?.[index] ===
                                          'object' &&
                                        (errors.dimensions[index] as any)
                                          .value ? (
                                          <Text
                                            as="span"
                                            fontSize="sm"
                                            color="red.500"
                                          >
                                            Select atleast one Repository
                                          </Text>
                                        ) : null}
                                      </Box>

                                      {listSelectModal.isOpen && (
                                        <ListSelect
                                          isOpen={listSelectModal.isOpen}
                                          onClose={listSelectModal.onClose}
                                          loading={false}
                                          initiallySelectedRows={values.dimensions[
                                            index
                                          ].value
                                            ?.split(',')
                                            .map((item: any) => ({
                                              label: item,
                                              value: item,
                                            }))}
                                          onSelectedRowsChange={(e: any) =>
                                            setFieldValue(
                                              `dimensions.${index}.value`,
                                              e
                                                ?.map((el: any) => el.label)
                                                ?.join(',')
                                            )
                                          }
                                          data={gitProjects?.dto?.map(
                                            (item: any) => ({
                                              value: item.name,
                                              label: item.name,
                                            })
                                          )}
                                          label={'Repository'}
                                          setTriggerSubmit={setTriggerSubmit}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <Box minWidth="150px">
                                      <Text
                                        fontFamily={'heading'}
                                        fontSize="sm"
                                        fontWeight="semibold"
                                        color="text.secondary"
                                      >
                                        Value
                                      </Text>
                                      <Box minW="">
                                        <Field
                                          name={`dimensions.${index}.value`}
                                        >
                                          {({ field }: any) => (
                                            <Input
                                              {...field}
                                              placeholder="Enter value"
                                              onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>
                                              ) => {
                                                setFieldValue(
                                                  `dimensions.${index}.value`,
                                                  e.target.value
                                                );
                                              }}
                                              value={
                                                values.dimensions[index].value
                                              }
                                            />
                                          )}
                                        </Field>
                                      </Box>
                                      {touched.dimensions?.[index] &&
                                      typeof errors.dimensions?.[index] ===
                                        'object' &&
                                      (errors.dimensions[index] as any)
                                        .value ? (
                                        <Text
                                          as="span"
                                          fontSize="sm"
                                          color="red.500"
                                        >
                                          {
                                            (errors.dimensions[index] as any)
                                              .value
                                          }
                                        </Text>
                                      ) : null}
                                    </Box>
                                  )}

                                  <Flex gap={2} mt={5}>
                                    <Tooltip hasArrow label="Delete">
                                      <IconButton
                                        aria-label="Delete"
                                        size="sm"
                                        icon={<DeleteIcon />}
                                        onClick={() => remove(index)}
                                        display={isMandatory ? 'none' : 'block'}
                                        border="1px solid #777"
                                      />
                                    </Tooltip>
                                  </Flex>
                                </Flex>
                              );
                            })}

                            <Flex align="center" gap={4}>
                              {dimensionData?.dto?.length !==
                                values?.dimensions?.length && (
                                <Tooltip hasArrow label="Add Dimension">
                                  <IconButton
                                    aria-label="Add Dimension"
                                    icon={<AddIcon />}
                                    size="sm"
                                    onClick={() => {
                                      if (!values.measure) {
                                        newToast({
                                          message:
                                            'Please select Measure to add dimensions',
                                          status: 'error',
                                        });
                                      } else {
                                        push({
                                          dimensionId: '',
                                          value: '',
                                          condition: 'EQUAL',
                                        });
                                      }
                                    }}
                                    border="1px dotted #777"
                                  />
                                </Tooltip>
                              )}

                              {touched.dimensions &&
                              typeof errors.dimensions === 'string' ? (
                                <Text as="span" fontSize="sm" color="red.500">
                                  {errors.dimensions}
                                </Text>
                              ) : null}
                            </Flex>
                          </Box>
                        )}
                      </FieldArray>
                    </Flex>

                    <Box w="full" h="1px" bg="#e0e0e0" />

                    <Flex align="center" gap={4}>
                      <Text
                        fontFamily={'heading'}
                        fontSize="sm"
                        fontWeight="semibold"
                        color="text.secondary"
                        mb={1}
                        width="100px"
                      >
                        Group By
                      </Text>

                      <Box minW="300px">
                        <Field name="groupBy">
                          {({ field, form }: any) => (
                            <Select
                              {...field}
                              useBasicStyles
                              options={groupByData?.dto?.map((item: any) => ({
                                label: item.displayName,
                                value: item.field,
                              }))}
                              placeholder="Group By"
                              onChange={(selected: any) => {
                                setShowChartForGroup(true);
                                setFieldValue('groupBy', selected);
                                setFieldValue('chartType', null);
                              }}
                              value={values.groupBy}
                            />
                          )}
                        </Field>
                        {touched.groupBy &&
                        typeof errors.groupBy === 'string' ? (
                          <Text as="span" fontSize="sm" color="red.500">
                            {errors.groupBy}
                          </Text>
                        ) : null}
                      </Box>
                      {values.groupBy && (
                        <Tooltip hasArrow label="Clear">
                          <IconButton
                            aria-label="clear"
                            icon={<MdOutlineClear size="18" />}
                            size="sm"
                            onClick={() => {
                              setFieldValue('groupBy', null);
                              setFieldValue('chartType', null);
                              setShowChartForGroup(false);
                            }}
                            border="1px dotted #777"
                          />
                        </Tooltip>
                      )}
                    </Flex>

                    <Box w="full" h="1px" bg="#e0e0e0" />

                    <Flex align="center" gap={4}>
                      <Text
                        fontFamily={'heading'}
                        fontSize="sm"
                        fontWeight="semibold"
                        color="text.secondary"
                        mb={1}
                        width="100px"
                      >
                        Chart Type
                      </Text>

                      <Box minW="300px">
                        <Field name="chartType">
                          {({ field, form }: any) => (
                            <Select
                              {...field}
                              useBasicStyles
                              options={
                                showChartForGroup
                                  ? chartTypeOptions2
                                  : chartTypeOptions1
                              }
                              placeholder="Select chart type"
                              onChange={(selected: any) => {
                                setFieldValue('chartType', selected);
                              }}
                              value={values.chartType}
                            />
                          )}
                        </Field>
                        {touched.chartType &&
                        typeof errors.chartType === 'string' ? (
                          <Text as="span" fontSize="sm" color="red.500">
                            {errors.chartType}
                          </Text>
                        ) : null}
                      </Box>
                    </Flex>

                    <Box w="full" h="1px" bg="#e0e0e0" />

                    <Flex align="center" gap={4}>
                      <Text
                        fontFamily={'heading'}
                        fontSize="sm"
                        fontWeight="semibold"
                        color="text.secondary"
                        mb={1}
                        width="100px"
                      >
                        Improvement
                      </Text>

                      <Box minW="300px">
                        <Field name="improvement">
                          {({ field, form }: any) => (
                            <Select
                              {...field}
                              useBasicStyles
                              options={improvementOptions}
                              placeholder="Select improvement"
                              onChange={(selected: any) => {
                                setFieldValue('improvement', selected);
                              }}
                              value={values.improvement}
                            />
                          )}
                        </Field>
                        {touched.improvement &&
                        typeof errors.improvement === 'string' ? (
                          <Text as="span" fontSize="sm" color="red.500">
                            {errors.improvement}
                          </Text>
                        ) : touched.improvement &&
                          typeof errors.improvement === 'object' ? (
                          <Text as="span" fontSize="sm" color="red.500">
                            Improvement is required
                          </Text>
                        ) : null}
                      </Box>
                    </Flex>

                    <Box w="full" h="1px" bg="#e0e0e0" />

                    <Box>
                      <Button
                        variant="tertiary"
                        fontSize="sm"
                        type="submit"
                        loadingText={'Saving'}
                      >
                        {metricSaved || metric ? 'Update' : 'Save'}
                      </Button>
                    </Box>
                  </Flex>
                </form>
              );
            }}
          </Formik>
          {showPreviewBox && (
            <Box
              border="1px solid"
              borderColor="#e0e0e0"
              height="440px"
              padding={4}
              width="100%"
            >
              {!data ? (
                <Text color="error">No Data</Text>
              ) : (
                <>
                  {typeof data?.average !== 'undefined' &&
                    data?.average !== null && (
                      <MetricLabelWrapper>
                        {typeof data?.average !== 'undefined' &&
                          data?.average !== null && (
                            <Count>
                              {data?.average &&
                              data?.chartMetadata?.dataType === 'TIME'
                                ? String(splitTime(data?.average / 60)).replace(
                                    ' 0hr',
                                    ''
                                  )
                                : data?.average}{' '}
                              {data?.chartMetadata?.dataType ===
                                'PERCENTAGE' && (
                                <Text fontSize={'18px'} display={'inline'}>
                                  %
                                </Text>
                              )}
                            </Count>
                          )}
                        <Label>
                          {data?.chartMetadata?.dataType !== 'PERCENTAGE' &&
                            data?.averageLabel}
                        </Label>
                      </MetricLabelWrapper>
                    )}

                  <Box w="full" height="360px">
                    <Chart
                      //   chartref={chartref}
                      data={data?.data}
                      keys={data?.keys}
                      chartMetadata={data?.chartMetadata}
                      showLabel={true}
                      selectedTeam={''}
                      hideBtn
                      showYLabel
                      //   ticketType={ticketType}
                      selected={selected}
                      //   queryKey={queryKey}
                    />
                  </Box>
                </>
              )}
            </Box>
          )}
        </Stack>
      </Box>
      {isFetching && <Loader />}
    </>
  );
};

export default AddMetric;
