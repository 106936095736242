import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { TeamFilter, AuthorIdFilter } from '../shared/utils';

interface MetricTableTypes extends TeamFilter {
  status: string;
  prSize: string;
  reviewer: string;
  filterAge: string;
  age: string;
  metricKey: string;
  chartKey: string;
  metric: string;
  feedbackResponse: string;
  reviewResponse: string;
  orderBy?: string;
  orderColumn?: string;
  previousFilter: string;
  //   metricHeading: string;
}

interface Actions {
  reset: () => void;
  resetFilters: () => void;
  setMetricFilter: (
    filterBy: TeamFilter,
    //     metricHeading: string,
    chartKey: string,
    metricKey: string
  ) => void;
  setDefault: (value: string) => void;
  setTeam: (value: string) => void;
  setPreviousFilter: (value: string) => void;
  setRepos: (value: string) => void;
  setAuthors: (value: string) => void;
  setReviewers: (value: string) => void;
  setPrSize: (value: string) => void;
  setPrAge: (value: string) => void;
  setReviewResponse: (value: string) => void;
  setReviewFeedback: (value: string) => void;
  setFeedbackResponse: (value: string) => void;
  setStatus: (value: string) => void;
  setSorting: (orderBy: string, orderColumn: string) => void;
}

const initialState: MetricTableTypes = {
  age: '',
  filterAge: '',
  metricKey: '',
  chartKey: '',
  metric: '',
  prSize: '',
  reviewer: '',
  status: '',
  teamId: '',
  author: '',
  name: '',
  projectsFilter: [],
  type: '',
  sprintId: '',
  orderBy: '',
  orderColumn: '',
  lowerBoundIncl: '',
  upperBound: null,
  reviewResponse: 'LESS_THAN_24HRS',
  feedbackResponse: 'LESS_THAN_24HRS',
  previousFilter: '',
  //   metricHeading: '',
};

export const useMetricsTableStore = create<MetricTableTypes & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      setMetricFilter: (
        filterBy: TeamFilter,
        //    metricHeading: string,
        chartKey: string,
        metricKey: string
      ) =>
        set(() => {
          return {
            ...filterBy,
            age: filterBy?.age ?? '',
            filterAge: filterBy?.age
              ? filterBy?.age === 'More than 1 Day'
                ? 'MORE_THAN_1D'
                : filterBy?.age === 'More than 3 Days'
                ? 'MORE_THAN_3D'
                : filterBy?.age === 'More than 7 Days'
                ? 'MORE_THAN_7D'
                : filterBy?.age === 'More than 14 Days'
                ? 'MORE_THAN_14D'
                : filterBy?.age === 'More than 1 Month'
                ? 'MORE_THAN_1M'
                : ''
              : '',
            prSize:
              filterBy?.lowerBoundIncl === 0
                ? '0-200'
                : filterBy?.lowerBoundIncl === 200
                ? '200-400'
                : filterBy?.lowerBoundIncl === 400
                ? '400-600'
                : filterBy?.lowerBoundIncl === 600
                ? '600-800'
                : filterBy?.lowerBoundIncl === 800
                ? '800+'
                : '',
            reviewer: [
              'TOP_REVIEWER_DISTRIBUTION',
              'PR_REVIEW',
              'PR_REVIEWED',
            ].includes(chartKey)
              ? filterBy?.author ?? ''
              : '',
            status: chartKey === 'PR_WITHOUT_ISSUE' ? 'merged' : '',
            teamId: filterBy?.teamId ?? '',
            author: ![
              'TOP_REVIEWER_DISTRIBUTION',
              'PR_REVIEW',
              'PR_REVIEWED',
            ].includes(chartKey)
              ? filterBy?.author ?? ''
              : '',
            name: filterBy?.name
              ? ['Feedback Responsiveness', 'Review Responsiveness'].includes(
                  filterBy?.name
                )
                ? `${filterBy?.name} Index`
                : filterBy?.name ?? ''
              : '',
            projectsFilter: filterBy?.projectsFilter ?? [],
            type:
              metricKey === 'merged_without_review' ||
              chartKey === 'MERGE_WITHOUT_REVIEW'
                ? 'merged_wo_review'
                : metricKey === 'merged_with_review'
                ? 'merged_with_review'
                : chartKey === 'LOC_VS_PR_CYCLE_TIME'
                ? 'pr_size'
                : filterBy?.name === 'Review Completion'
                ? 'review_with_comment'
                : chartKey === 'PR_WITHOUT_ISSUE'
                ? 'prs_unlinked'
                : '',
            sprintId: filterBy?.sprintId ?? '',
            lowerBoundIncl: filterBy?.lowerBoundIncl ?? '',
            upperBound: filterBy?.upperBound ? filterBy?.upperBound : null,
            reviewResponse: 'LESS_THAN_24HRS',
            feedbackResponse: 'LESS_THAN_24HRS',

            metricKey,
            chartKey,
            // metricHeading,
          };
        }),
      setDefault: (value: string) =>
        set((state) => ({
          type: value,
          prSize: state.chartKey === 'LOC_VS_PR_CYCLE_TIME' ? state.prSize : '',
          filterAge:
            state.chartKey === 'PR_AGEING_DISTRIBUTION' ? state.filterAge : '',
          projectsFilter: [],
          name:
            state.chartKey === 'REVIEW_RESPONSIVENESS_INDEX' ? state.name : '',
          author: '',
          reviewer: [
            'TOP_REVIEWER_DISTRIBUTION',
            'PR_REVIEW',
            'PR_REVIEWED',
          ].includes(state.chartKey)
            ? state.reviewer
            : '',
        })),

      setRepos: (value: string) =>
        set((state) => ({
          projectsFilter: [value],
          type: '',
          prSize: state.chartKey === 'LOC_VS_PR_CYCLE_TIME' ? state.prSize : '',
          name:
            state.chartKey === 'REVIEW_RESPONSIVENESS_INDEX' ? state.name : '',
          filterAge:
            state.chartKey === 'PR_AGEING_DISTRIBUTION' ? state.filterAge : '',
          author: '',
          reviewer: [
            'TOP_REVIEWER_DISTRIBUTION',
            'PR_REVIEW',
            'PR_REVIEWED',
          ].includes(state.chartKey)
            ? state.reviewer
            : '',
        })),
      setAuthors: (value: string) =>
        set((state) => ({
          author: value,
          reviewer: [
            'TOP_REVIEWER_DISTRIBUTION',
            'PR_REVIEW',
            'PR_REVIEWED',
          ].includes(state.chartKey)
            ? state.reviewer
            : '',
          type: '',
          name:
            state.chartKey === 'REVIEW_RESPONSIVENESS_INDEX' ? state.name : '',
          prSize: state.chartKey === 'LOC_VS_PR_CYCLE_TIME' ? state.prSize : '',
          filterAge:
            state.chartKey === 'PR_AGEING_DISTRIBUTION' ? state.filterAge : '',
          projectsFilter: [],
        })),
      setReviewers: (value: string) =>
        set((state) => ({
          reviewer: value,
          author: '',
          type: '',
          prSize: state.chartKey === 'LOC_VS_PR_CYCLE_TIME' ? state.prSize : '',
          name:
            state.chartKey === 'REVIEW_RESPONSIVENESS_INDEX' ? state.name : '',
          filterAge:
            state.chartKey === 'PR_AGEING_DISTRIBUTION' ? state.filterAge : '',
          projectsFilter: [],
        })),
      setPrSize: (value: string) =>
        set((state) => ({
          prSize: value,
          type: 'pr_size',
          author: '',
          projectsFilter: [],
          name:
            state.chartKey === 'REVIEW_RESPONSIVENESS_INDEX' ? state.name : '',
          filterAge:
            state.chartKey === 'PR_AGEING_DISTRIBUTION' ? state.filterAge : '',
          reviewer: [
            'TOP_REVIEWER_DISTRIBUTION',
            'PR_REVIEW',
            'PR_REVIEWED',
          ].includes(state.chartKey)
            ? state.reviewer
            : '',
          lowerBoundIncl:
            value === '0-200'
              ? 0
              : value === '200-400'
              ? 200
              : value === '400-600'
              ? 400
              : value === '600-800'
              ? 600
              : value === '800+'
              ? 800
              : '',
          upperBound:
            value === '0-200'
              ? 200
              : value === '200-400'
              ? 400
              : value === '400-600'
              ? 600
              : value === '600-800'
              ? 800
              : value === '800+'
              ? null
              : null,
        })),
      setPrAge: (value: string) =>
        set((state) => ({
          filterAge: value,

          age:
            value === 'MORE_THAN_1D'
              ? 'More than 1 Day'
              : value === 'MORE_THAN_3D'
              ? 'More than 3 Days'
              : value === 'MORE_THAN_7D'
              ? 'More than 7 Days'
              : value === 'MORE_THAN_14D'
              ? 'More than 14 Days'
              : 'More than 1 Month',
          prSize: state.chartKey === 'LOC_VS_PR_CYCLE_TIME' ? state.prSize : '',
          type: '',
          name:
            state.chartKey === 'REVIEW_RESPONSIVENESS_INDEX' ? state.name : '',
          author: '',

          projectsFilter: [],
          reviewer: [
            'TOP_REVIEWER_DISTRIBUTION',
            'PR_REVIEW',
            'PR_REVIEWED',
          ].includes(state.chartKey)
            ? state.reviewer
            : '',
        })),
      setReviewResponse: (value: string) =>
        set((state) => ({
          prSize: state.chartKey === 'LOC_VS_PR_CYCLE_TIME' ? state.prSize : '',
          filterAge:
            state.chartKey === 'PR_AGEING_DISTRIBUTION' ? state.filterAge : '',
          type: '',
          author: '',
          projectsFilter: [],
          reviewResponse: value,
          name: 'Review Responsiveness Index',
          reviewer: [
            'TOP_REVIEWER_DISTRIBUTION',
            'PR_REVIEW',
            'PR_REVIEWED',
          ].includes(state.chartKey)
            ? state.reviewer
            : '',
        })),
      setReviewFeedback: (value: string) =>
        set((state) => ({
          prSize: state.chartKey === 'LOC_VS_PR_CYCLE_TIME' ? state.prSize : '',
          filterAge:
            state.chartKey === 'PR_AGEING_DISTRIBUTION' ? state.filterAge : '',
          type: value,
          author: '',
          projectsFilter: [],
          name: 'Review Completion',
          reviewer: [
            'TOP_REVIEWER_DISTRIBUTION',
            'PR_REVIEW',
            'PR_REVIEWED',
          ].includes(state.chartKey)
            ? state.reviewer
            : '',
        })),
      setFeedbackResponse: (value: string) =>
        set((state) => ({
          prSize: state.chartKey === 'LOC_VS_PR_CYCLE_TIME' ? state.prSize : '',
          filterAge:
            state.chartKey === 'PR_AGEING_DISTRIBUTION' ? state.filterAge : '',
          type: '',
          author: '',
          projectsFilter: [],
          feedbackResponse: value,
          name: 'Feedback Responsiveness Index',
          reviewer: [
            'TOP_REVIEWER_DISTRIBUTION',
            'PR_REVIEW',
            'PR_REVIEWED',
          ].includes(state.chartKey)
            ? state.reviewer
            : '',
        })),
      setStatus: (value: string) => {
        return set(() => ({
          status: value,
        }));
      },
      setTeam: (value: string) =>
        set(() => ({
          teamId: value,
        })),
      setPreviousFilter: (value: string) =>
        set(() => ({
          previousFilter: value,
        })),
      setSorting: (orderBy: string, orderColumn: string) =>
        set(() => ({
          orderBy,
          orderColumn,
        })),
      reset: () => set(() => initialState),
      resetFilters: () => set(() => initialState),
    }),
    {
      name: 'MetricFilters', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export default useMetricsTableStore;
